import { addDays } from 'date-fns';
import * as React from 'react';
import { isRelativeTimeString, relativeTimeStringToDate, toIso8601Date } from '../utils/dateTimeUtilities';
import { useMultiSearchParamState } from './useSearchParamState';

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

/** Use a date range that is maintained in URL Search Parameters */
export function useSearchParamDateRange(initialDateRange?: DateRange) {
  const initialDateRangeInternal = React.useMemo(
    () =>
      initialDateRange ?? {
        startDate: new Date(addDays(new Date().getTime(), -1).getTime()),
        endDate: new Date(new Date().getTime()),
      },
    [initialDateRange],
  );

  const [dateRange, setDateRange] = useMultiSearchParamState({
    startDate: {
      initialValue: initialDateRangeInternal.startDate,
      serde: {
        serializer: toIso8601Date,
        deserializer: (value: string) =>
          isRelativeTimeString(value) ? relativeTimeStringToDate(value) : new Date(value),
      },
      paramKey: 'startTime',
    },
    endDate: {
      initialValue: initialDateRangeInternal.endDate,
      serde: {
        serializer: toIso8601Date,
        deserializer: (value: string) =>
          isRelativeTimeString(value) ? relativeTimeStringToDate(value) : new Date(value),
      },
      paramKey: 'endTime',
    },
  });

  const resetDateRange = React.useCallback(() => {
    setDateRange(initialDateRangeInternal);
  }, [initialDateRangeInternal, setDateRange]);

  return [dateRange, setDateRange, resetDateRange] as const;
}
