import AWS_ACCOUNTS from '@amzn/aws-gsaas-accounts-helper/dist/configuration/aws_gsaas_aws_accounts_config/aws_accounts.json';
import type { AccountsConfig } from '@amzn/aws-gsaas-accounts-helper/dist/account-helper-browser';
import { AccountHelperBrowser } from '@amzn/aws-gsaas-accounts-helper/dist/account-helper-browser';
import type { AntennaSystemAwsAccount } from '../clients/MgmtCellClientManager';
import { isDefined } from '@amzn/aws-gsaas-common-typescript';
import { uniqBy } from 'lodash';
import type { AntennaSystem } from '@amzn/aws-gsaas-accounts-helper';

export const accountsHelper = new AccountHelperBrowser(AWS_ACCOUNTS as unknown as AccountsConfig);

export const GSOC_NAME_PART = 'opsconsole';
export const ANALYTICS_NAME_PART = 'analytics';
export const CANARY_NAME_PART = 'canary';

export enum ACCOUNT_TYPE {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
  Canary = 'CANARY',
}

export const INTERNAL_ACCOUNT = 'INTERNAL';
export const EXTERNAL_ACCOUNT = 'EXTERNAL';
export const CANARY_ACCOUNT = 'CANARY';

export function getAccountNamePart(accountId: string): string {
  if (accountsHelper.accountIds.includes(accountId)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return accountsHelper.awsAccounts[accountId]!.name_part;
  } else {
    return ACCOUNT_TYPE.External;
  }
}

export function getAccountName(accountId: string | null): string | null {
  if (accountId === null) return null;
  return accountsHelper.awsAccounts[accountId]?.name ?? null;
}

export function getAccountType(namePart: string): ACCOUNT_TYPE {
  if (namePart === EXTERNAL_ACCOUNT) {
    return ACCOUNT_TYPE.External;
  }
  if (namePart.includes(CANARY_NAME_PART)) {
    return ACCOUNT_TYPE.Canary;
  }
  return ACCOUNT_TYPE.Internal;
}

export function getAntennaSystemAccounts(stage: string): AntennaSystemAwsAccount[] {
  return accountsHelper
    .getAccounts('mgmt', stage)
    .filter(account => account.antenna_systems !== undefined && account.antenna_systems.length > 0)
    .map(
      account =>
        ({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          antenna_system: account.antenna_systems![0],
          ...account,
        }) as AntennaSystemAwsAccount,
    )
    .sort((a, b) => getAntennaName(a, stage).localeCompare(getAntennaName(b, stage)));
}

export function getAntennaToAccountMap(stage: string): { [antennaName: string]: AntennaSystemAwsAccount } {
  return Object.fromEntries(getAntennaSystemAccounts(stage).map(account => [account.antenna_system.id, account]));
}

/** Preprod data is wonky, take this with you */
export function getAntennaName(antannaSystemOrAccount: AntennaSystemAwsAccount | AntennaSystem, stage: string): string {
  if ('antenna_system' in antannaSystemOrAccount) {
    return stage === 'prod' ? antannaSystemOrAccount.antenna_system.id : antannaSystemOrAccount.antenna_system.alt_id;
  } else {
    return stage === 'prod' ? antannaSystemOrAccount.id : antannaSystemOrAccount.alt_id;
  }
}

export function getGroundstationToAntennaMap(stage: string) {
  const map: { [siteName: string]: AntennaSystem[] } = {};
  for (const account of getAntennaSystemAccounts(stage)) {
    const groundStation = account.antenna_system.ground_station_id;
    if (groundStation in map) {
      map[groundStation]?.push(account.antenna_system);
    } else {
      map[groundStation] = [account.antenna_system];
    }
  }
  return map;
}

export function getAntennaToGroundstationMap(stage: string) {
  const groundStationToAntennaMap = getGroundstationToAntennaMap(stage);
  const antennaToGroundStationMap: { [antennaName: string]: string } = {};
  for (const [groundStation, antennae] of Object.entries(groundStationToAntennaMap)) {
    for (const antenna of antennae) {
      antennaToGroundStationMap[getAntennaName(antenna, stage)] = groundStation;
    }
  }
  return antennaToGroundStationMap;
}

export function getSites(stage: string): { groundStationId: string; region: string }[] {
  return uniqBy(
    accountsHelper
      .getAccounts('mgmt', stage)
      .filter(account => account.antenna_systems !== undefined && account.antenna_systems.length > 0)
      .flatMap(account =>
        account.antenna_systems?.map(antenna_system => ({
          groundStationId: antenna_system.ground_station_id,
          region: account.region,
        })),
      )
      .filter(isDefined)
      .sort((a, b) => a.groundStationId.localeCompare(b.groundStationId)),
    gs => gs.groundStationId,
  );
}
