import { DefaultApi as GSOCApi } from '@amzn/aws-gsaas-gsoc-backend-typescript-client';
import * as React from 'react';
import axios from 'axios';
import type { QueryFunctionContext, UseQueryResult } from '@tanstack/react-query';
import { useQueries, useQuery } from '@tanstack/react-query';
import type { GsocIamRoleFragment } from '../code-generated/graphqlOperations.generated';
import { QueryKeys } from '../constants/queryKeys';
import { PermissionClaim } from '../code-generated/PermissionClaim.generated';
import { LegacyIamRolesContext } from './LegacyIamRoles';
import { useAppSettings } from './AppSettings';
import { useMidwayToken } from './Midway';
import { useAddNotification } from './Notifications';
import { useDoesUserHavePermissionClaim } from './Permissions';

const TIMEOUT_BUFFER_SECONDS = 30;
const RETRY_ATTEMPTS = 3;

export function LegacyIamRolesProvider(props: { readonly children: React.ReactNode }) {
  const addNotification = useAddNotification();
  const { apiUrl } = useAppSettings();
  const canDiscoverIamRole = useDoesUserHavePermissionClaim(PermissionClaim.DiscoverIamRole);
  const midwayToken = useMidwayToken();

  /**
   * Add Authorization header to all axios calls for backend API
   *
   * NOTE: if this is called multiple times without ejecting the interceptor,
   * it will add multiple of the same interceptors This caused issues after
   * the client was made several times. Here, we use the useEffect cleanup
   * return function to deregister the interceptor
   *
   * It is important that this effect is run prior to the data fetching below
   * so that the interceptor is registered before any data is fetched.
   */
  React.useEffect(() => {
    const interceptorId = axios.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${midwayToken}`;
      return config;
    });

    return () => axios.interceptors.request.eject(interceptorId);
  }, [midwayToken]);

  const gsocApiClient = React.useMemo(() => new GSOCApi(undefined, `${apiUrl}/jwt/v1`), [apiUrl]);

  const { data, refetch, error } = useQuery({
    queryKey: [QueryKeys.LIST_ROLES],
    queryFn: () => gsocApiClient.listRoles().then(res => res.data),
    enabled: canDiscoverIamRole === true,
  });

  React.useEffect(() => {
    if (error !== null) {
      const err = new Error(`Error listing IAM Roles`, { cause: error });
      console.error(err);
      addNotification({
        type: 'error',
        header: `Error listing IAM Roles`,
        message: err.message,
      });
    }
  }, [addNotification, error]);

  /** Refetch readonly credentials when midway token changes, or permission status changes */
  React.useEffect(() => {
    if (canDiscoverIamRole === true) {
      void refetch();
    }
  }, [midwayToken, canDiscoverIamRole, refetch]);

  const { results } = useQueries({
    queries: (data?.roleList ?? []).map(role => ({
      queryKey: [QueryKeys.GSOC_CREDENTIALS, { roleName: role.roleName }],
      queryFn: async ({ signal }: { signal?: QueryFunctionContext['signal'] }) => {
        try {
          const response = await gsocApiClient.getCredentials(role.roleName, {
            signal,
          });

          return {
            name: role.roleName,
            roleArn: role.roleArn,
            isAuthorized: role.isAuthorized,
            awsTemporaryCredentials: {
              accessKeyId: response.data.credentials.accessKeyId,
              secretAccessKey: response.data.credentials.secretAccessKey,
              sessionToken: response.data.credentials.sessionToken,
              expiration: new Date(response.data.credentials.expiration),
            },
          };
        } catch (_) {
          return {
            name: role.roleName,
            roleArn: role.roleArn,
            isAuthorized: role.isAuthorized,
            awsTemporaryCredentials: null,
          };
        }
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: RETRY_ATTEMPTS,
      onError: (err: unknown) => {
        console.error(`Error getting credentials for role: ${role.roleName}`, err);
        addNotification({
          type: 'error',
          header: `Error getting credentials for role: ${role.roleName}`,
          message: (err as Error).message,
        });
      },
    })),
    combine: React.useCallback(
      (
        results: UseQueryResult<{
          name: string;
          roleArn: string;
          isAuthorized: boolean;
          awsTemporaryCredentials: {
            accessKeyId: string;
            secretAccessKey: string;
            sessionToken: string;
            expiration: Date;
          } | null;
        }>[],
      ) => ({
        results: results.map(result => ({
          data: result.data,
          refetch: result.refetch,
        })),
      }),
      [],
    ),
  });

  /** Refetch credentials just before they expire */
  React.useEffect(() => {
    const timeoutHandles: number[] = [];
    for (const { refetch, data } of results) {
      const expirationDate = data?.awsTemporaryCredentials?.expiration;
      if (expirationDate !== undefined) {
        const timeoutDuration = ((expirationDate.getTime() - Date.now()) / 1000 - TIMEOUT_BUFFER_SECONDS) * 1000;
        const timeoutHandle = window.setTimeout(() => {
          void refetch();
          timeoutHandles.splice(timeoutHandles.indexOf(timeoutHandle), 1);
        }, timeoutDuration);
        timeoutHandles.push(timeoutHandle);
      }
    }
    return () => {
      for (const timeoutHandle of timeoutHandles) {
        window.clearTimeout(timeoutHandle);
      }
    };
  }, [results]);

  /** Memoize the context value so that it doesn't change on every render */
  const iamRolesList = React.useMemo<readonly GsocIamRoleFragment[]>(() => {
    if (data === undefined) return [];
    const roles: GsocIamRoleFragment[] = [];

    for (const gsocCredentialsQuery of results) {
      if (gsocCredentialsQuery.data !== undefined) {
        roles.push(gsocCredentialsQuery.data);
      }
    }
    return roles;
  }, [data, results]);

  return <LegacyIamRolesContext.Provider value={iamRolesList}>{props.children}</LegacyIamRolesContext.Provider>;
}
