import * as React from 'react';
import { MgmtCellClientManager } from '../clients/MgmtCellClientManager';
import { PermissionClaim } from '../code-generated/PermissionClaim.generated';
import { useAppSettings } from './AppSettings';
import { MgmtCellClientManagerContext } from './MgmtCellClientManager';
import { useUsername } from './Midway';
import { NOT_LOADED, useCredentialsForPermissionClaim } from './Permissions';

/**
 * MgmtCellClientManager context.
 *
 * Used to create and provide a MgmtCellClientManager to the application.
 */
export function MgmtCellClientManagerProvider(props: { readonly children: React.ReactNode }) {
  const username = useUsername();
  const { region, stage } = useAppSettings();
  const readMaintenanceCreds = useCredentialsForPermissionClaim(PermissionClaim.ReadMaintenance);
  const scheduleMaintenanceCreds = useCredentialsForPermissionClaim(PermissionClaim.ScheduleMaintenance);
  const readAntennaSiteMetricCreds = useCredentialsForPermissionClaim(PermissionClaim.ReadSiteAntennaMetrics);

  const readMaintenanceClient = React.useMemo(() => {
    if (readMaintenanceCreds === null || readMaintenanceCreds === NOT_LOADED) return null;
    return new MgmtCellClientManager({
      credentials: readMaintenanceCreds,
      gsocRegion: region,
      stage,
      username,
      permission: PermissionClaim.ReadMaintenance,
    });
  }, [readMaintenanceCreds, stage, region, username]);

  const scheduleMaintenanceClient = React.useMemo(() => {
    if (scheduleMaintenanceCreds === null || scheduleMaintenanceCreds === NOT_LOADED) return null;
    return new MgmtCellClientManager({
      credentials: scheduleMaintenanceCreds,
      gsocRegion: region,
      stage,
      username,
      permission: PermissionClaim.ScheduleMaintenance,
    });
  }, [scheduleMaintenanceCreds, stage, region, username]);

  const readSiteAntennaMetricsClient = React.useMemo(() => {
    if (readAntennaSiteMetricCreds === null || readAntennaSiteMetricCreds === NOT_LOADED) return null;
    return new MgmtCellClientManager({
      credentials: readAntennaSiteMetricCreds,
      gsocRegion: region,
      stage,
      username,
      permission: PermissionClaim.ReadSiteAntennaMetrics,
    });
  }, [readAntennaSiteMetricCreds, stage, region, username]);

  const providedValue = React.useMemo(
    () => ({
      [PermissionClaim.ReadMaintenance]: readMaintenanceClient,
      [PermissionClaim.ScheduleMaintenance]: scheduleMaintenanceClient,
      [PermissionClaim.ReadSiteAntennaMetrics]: readSiteAntennaMetricsClient,
    }),
    [readMaintenanceClient, scheduleMaintenanceClient, readSiteAntennaMetricsClient],
  );

  return (
    <MgmtCellClientManagerContext.Provider value={providedValue}>
      {props.children}
    </MgmtCellClientManagerContext.Provider>
  );
}
