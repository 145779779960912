import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CommonAppLayout } from './components/CommonAppLayout';
import { BREADCRUMBS, Pages } from './pages/pages';
import { FeatureFlags, useFeatureFlag } from './context/FeatureFlags';
import { DataPlaneDashboardPage } from './pages/atacama/Dashboards/DataPlaneDashboardPage';

const SiteTimelinesPage = React.lazy(() => import('./pages/SiteTimelinesPage'));
const SitePage = React.lazy(() => import('./pages/SitePage'));
const ContactSearchPage = React.lazy(() => import('./pages/ContactSearchPage'));
const OldContactDetailPage = React.lazy(() => import('./pages/ContactDetailsPage'));
const ContactDetailPage = React.lazy(() => import('./pages/atacama/ContactDetailsPage'));
const OldContactListPage = React.lazy(() => import('./pages/ContactsListPage'));
const ContactListPage = React.lazy(() => import('./pages/atacama/ContactsListPage'));
const MaintenanceListPage = React.lazy(() => import('./pages/MaintenanceListPage'));
const MaintenanceSchedulePage = React.lazy(() => import('./pages/MaintenanceSchedulePage'));
const MaintenanceDetailsPage = React.lazy(() => import('./pages/MaintenanceDetailsPage'));
const DataExplorerPage = React.lazy(() => import('./pages/atacama/DataExplorerPage'));
const AuthorizationPage = React.lazy(() => import('./pages/AuthorizationPage'));
const OncallPage = React.lazy(() => import('./pages/OncallPage'));
const HomePage = React.lazy(() => import('./pages/HomePage'));
const SnapPage = React.lazy(() => import('./pages/SnapPage'));
const SnapSitePage = React.lazy(() => import('./pages/SnapSitePage'));

// Note well: order of pages matters here.
export default function App() {
  const [graphqlApiFeatureFlag] = useFeatureFlag(FeatureFlags.graphqlApi);
  return (
    <Switch>
      <Route exact path={Pages.SiteTimelines}>
        <React.Suspense
          fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.SiteTimelines]} />}
        >
          <SiteTimelinesPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.Site}>
        <React.Suspense fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home]} />}>
          <SitePage />
        </React.Suspense>
      </Route>
      <Route exact path={Pages.SNAP}>
        <React.Suspense fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.SNAP]} />}>
          <SnapPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.SNAPSite}>
        <React.Suspense fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.SNAP]} />}>
          <SnapSitePage />
        </React.Suspense>
      </Route>
      <Route path={Pages.MaintenanceSchedule}>
        <React.Suspense
          fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.MaintenanceSchedule]} />}
        >
          <MaintenanceSchedulePage />
        </React.Suspense>
      </Route>
      <Route path={Pages.MaintenanceDetails}>
        <React.Suspense
          fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.MaintenanceList]} />}
        >
          <MaintenanceDetailsPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.MaintenanceList}>
        <React.Suspense
          fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.MaintenanceList]} />}
        >
          <MaintenanceListPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.DataPlaneDashboard}>
        <React.Suspense
          fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.DataPlaneDashboard]} />}
        >
          <DataPlaneDashboardPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.DataExplorer}>
        <React.Suspense
          fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.DataExplorer]} />}
        >
          <DataExplorerPage />
        </React.Suspense>
      </Route>
      <Route exact path={Pages.Contact}>
        <React.Suspense fallback={<CommonAppLayout breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.ContactSearch]} />}>
          <ContactSearchPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.ContactDetails}>
        <React.Suspense fallback={<CommonAppLayout breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.ContactSearch]} />}>
          {graphqlApiFeatureFlag ? <ContactDetailPage /> : <OldContactDetailPage />}
        </React.Suspense>
      </Route>
      <Route path={Pages.ContactList}>
        <React.Suspense fallback={<CommonAppLayout breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.ListContacts]} />}>
          {graphqlApiFeatureFlag ? <ContactListPage /> : <OldContactListPage />}
        </React.Suspense>
      </Route>
      <Route path={Pages.Authorization}>
        <React.Suspense
          fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.Authorization]} />}
        >
          <AuthorizationPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.Oncall}>
        <React.Suspense fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.Oncall]} />}>
          <OncallPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.HomePage}>
        <React.Suspense fallback={<CommonAppLayout toolsHide breadcrumbs={[BREADCRUMBS.Home]} />}>
          <HomePage />
        </React.Suspense>
      </Route>
      <Redirect to={Pages.HomePage} />
    </Switch>
  );
}
