import type { MaintenanceWindowData, MetricDataQuery } from '@amzn/aws-gsaas-antenna-lambda-client';
import { GroundstationAntennaLambda } from '@amzn/aws-gsaas-antenna-lambda-client';
import { GroundStationMgmtCellCapabilityDeviceConfigLambda } from '@amzn/aws-gsaas-mgmt-cell-capability-device-config-client';
import type { AwsCredentialIdentity } from '@aws-sdk/types';
import type { AntennaSystem, AwsAccount } from '@amzn/aws-gsaas-accounts-helper';
import {
  accountsHelper,
  getAntennaName,
  getAntennaSystemAccounts,
  getAntennaToAccountMap,
} from '../utils/accountsUtils';
import { getRoleArn } from '../utils/gsRoleUtils';
import type { DateRange } from '../hooks/useSearchParamDateRange';
import { buildAwsTemporaryCredentialProvider } from './buildAwsTemporaryCredentialProvider';
import { ClientManager } from './ClientManager';
import { InFlightPromiseThrottler } from '@amzn/aws-gsaas-common-typescript/PromiseThrottler';

// Map colo codes to airport codes to regional airport codes
const COLO_CODES_TO_AIRPORT_MAP = new Map<string, string>([
  ['dbo', 'syd'],
  ['puq', 'gru'],
  ['scc', 'pdx'],
  ['hnl', 'pdx'],
  ['lab', 'iad'],
]);

export function toMaintenanceWindow(window: MaintenanceWindowData, antenna: string) {
  const descriptionData: MaintenanceWindowAdditionalInfo =
    window.additionalInfo === undefined
      ? { stepFunctionExecutionId: 'undefined' }
      : (JSON.parse(window.additionalInfo) as MaintenanceWindowAdditionalInfo);
  return {
    data: descriptionData,
    antennaName: antenna,
    ...window,
  };
}

export interface MaintenanceWindow extends MaintenanceWindowData {
  data: MaintenanceWindowAdditionalInfo;
  antennaName?: string;
}
export interface MaintenanceWindowAdditionalInfo {
  stepFunctionExecutionId: string;
}
/**
 * Transform into more easily accessible antenna system account (non-null)
 */
export interface AntennaSystemAwsAccount extends AwsAccount {
  antenna_system: AntennaSystem;
}
export class MgmtCellClientManager extends ClientManager<GroundstationAntennaLambda> {
  private antennasSystemAccounts: AntennaSystemAwsAccount[] | undefined;
  private antennaToAccountMap: Map<string, AntennaSystemAwsAccount> | undefined;
  private antennaToGroundstationMap = new Map<string, Map<string, AntennaSystemAwsAccount[]>>();
  private cancelMaintenanceThrottler = new InFlightPromiseThrottler(10);
  private readonly capabilityDeviceClientMap = new Map<string, GroundStationMgmtCellCapabilityDeviceConfigLambda>();

  /**
   * Load management cell accounts with defined antenna systems
   */
  public getAntennaSystems(): AntennaSystemAwsAccount[] {
    if (this.antennasSystemAccounts !== undefined) {
      return this.antennasSystemAccounts;
    }
    const accounts = accountsHelper.getAccounts('mgmt', this.stage);
    const antennaSystemAccounts = accounts
      .filter(account => account.antenna_systems !== undefined && account.antenna_systems.length > 0)
      .map(account => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        antenna_system: account.antenna_systems![0],
        ...account,
      })) as AntennaSystemAwsAccount[];
    this.antennasSystemAccounts = antennaSystemAccounts;
    return this.antennasSystemAccounts;
  }

  public getAntennaToAccountMap(): Map<string, AntennaSystemAwsAccount> {
    if (this.antennaToAccountMap !== undefined) {
      return this.antennaToAccountMap;
    }
    this.antennaToAccountMap = new Map(this.getAntennaSystems().map(account => [account.antenna_system.id, account]));
    return this.antennaToAccountMap;
  }

  public async loadMaintenanceWindows(props: { antenna: string; startTime: Date; endTime: Date }) {
    let nextToken: string | undefined;
    const maintenanceList: MaintenanceWindow[] = [];
    do {
      const client = this.getAntennaControlClientForCell({
        antenna: props.antenna,
      });
      const response = await client.listMaintenance({
        startTime: props.startTime,
        endTime: props.endTime,
        maxResults: 100,
      });
      nextToken = response.nextToken;
      const windows: MaintenanceWindow[] = (response.windows ?? []).map(window =>
        toMaintenanceWindow(window, props.antenna),
      );
      maintenanceList.push(...windows);
    } while (nextToken != null);
    return maintenanceList;
  }

  public async loadMaintenanceWindowsForSites(props: { siteIds: string[]; startTime: Date; endTime: Date }) {
    const maintenanceList: MaintenanceWindow[] = [];

    const antennaNames = getAntennaSystemAccounts(this.stage)
      .filter(antennaSystemAccount => props.siteIds.includes(antennaSystemAccount.antenna_system.ground_station_id))
      .map(antennaSystemAccount => getAntennaName(antennaSystemAccount, this.stage));

    await Promise.all(
      antennaNames.map(async antenna => {
        const antennaMaintenaceList = await this.loadMaintenanceWindows({
          antenna,
          startTime: props.startTime,
          endTime: props.endTime,
        });
        maintenanceList.push(...antennaMaintenaceList);
      }),
    );

    return maintenanceList;
  }

  public async deleteMaintenanceWindow(props: { antenna: string; reservationId: string }) {
    const client = this.getAntennaControlClientForCell({
      antenna: props.antenna,
    });
    const response = await this.cancelMaintenanceThrottler.run(() =>
      client.cancelMaintenance({
        maintenanceReservationId: props.reservationId,
      }),
    );
    return response.maintenanceReservationId;
  }

  public async getAntennaMetrics(props: {
    antenna: string;
    metricDataQuery: MetricDataQuery[];
    startTime: Date;
    endTime: Date;
  }) {
    const client = this.getAntennaControlClientForCell({
      antenna: props.antenna,
    });
    const response = await client.getMetrics({
      StartTime: props.startTime,
      EndTime: props.endTime,
      MetricDataQueries: props.metricDataQuery,
    });
    return response.MetricDataResults;
  }

  public async deleteAllMaintenanceWindows(props: { reservations: MaintenanceWindow[] }) {
    return Promise.all(
      props.reservations.map(id =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.deleteMaintenanceWindow({ antenna: id.antennaName!, reservationId: id.maintenanceReservationId! }),
      ),
    );
  }

  public async describeMaintenance(props: { antenna: string; reservationId: string }) {
    const client = this.getAntennaControlClientForCell({
      antenna: props.antenna,
    });
    return await client.describeMaintenance({
      maintenanceReservationId: props.reservationId,
    });
  }

  public async scheduleMaintenanceWindowAsync(props: {
    antenna: string;
    dateRange: DateRange;
    justification: string;
    user: string;
    contactsToCancel: string[];
    requestReason: string;
    deploymentWindow: boolean;
  }) {
    const client = this.getAntennaControlClientForCell({
      antenna: props.antenna,
    });
    const response = await client.scheduleMaintenanceAsync({
      startTime: props.dateRange.startDate,
      endTime: props.dateRange.endDate,
      justification: props.justification,
      user: props.user,
      deploymentWindow: props.deploymentWindow,
      schedulingReason: props.requestReason,
      contactsToCancel: props.contactsToCancel,
    });
    return response.maintenanceReservationId;
  }

  private getAntennaControlClientForCell(opts: { antenna: string }): GroundstationAntennaLambda {
    const localizedClient = this.clientMap.get(opts.antenna);
    if (localizedClient !== undefined) return localizedClient;

    // Create new client, cache it, return it, and set a timeout to refresh it later
    const newClient = this.buildAntennaControlClientForCell({
      antenna: opts.antenna,
      gsocRegion: this.gsocRegion,
      stage: this.stage,
      username: this.username,
      credentials: this.credentials,
    });
    this.clientMap.set(opts.antenna, newClient);

    return newClient;
  }

  public async loadTransformerCapabilities(opts: { antenna: string; transformerName?: string }) {
    const client = this.getCapabilityDeviceClientForCell({
      antenna: opts.antenna,
    });
    const response = await client.listTransformerCapabilitiesV2({ transformerName: opts.transformerName });

    return response.capabilities;
  }

  public async listTransformerStatuses(opts: { antenna: string; transformerName: string }) {
    const client = this.getCapabilityDeviceClientForCell({
      antenna: opts.antenna,
    });
    const response = await client.listTransformers({});

    return response.transformers;
  }

  private getCapabilityDeviceClientForCell(opts: {
    antenna: string;
  }): GroundStationMgmtCellCapabilityDeviceConfigLambda {
    const localizedClient = this.capabilityDeviceClientMap.get(opts.antenna);
    if (localizedClient !== undefined) return localizedClient;

    // Create new client, cache it, return it, and set a timeout to refresh it later
    const newClient = this.buildCapbilityDeviceClientForCell({
      antenna: opts.antenna,
      gsocRegion: this.gsocRegion,
      stage: this.stage,
      username: this.username,
      credentials: this.credentials,
    });
    this.capabilityDeviceClientMap.set(opts.antenna, newClient);

    return newClient;
  }

  private buildAntennaControlClientForCell(opts: {
    credentials: AwsCredentialIdentity;
    username: string;
    stage: string;
    antenna: string;
    gsocRegion: string;
  }): GroundstationAntennaLambda {
    const account = getAntennaToAccountMap(this.stage)[opts.antenna];
    if (account === undefined) {
      throw new Error(`Cannot locate management cell account for ${opts.antenna} and ${opts.stage}`);
    }
    const service = account.services.find(service => service.service_key === 'managementcell');
    if (service === undefined) {
      throw new Error(`Failed to locate service managementcell for ${account.account_id}`);
    }
    console.debug('Found endpoint: ', service.endpoint);

    // Quick way to get airport code for antennas
    const airportCode = account.cell_partition.substring(0, 3).toLowerCase();
    const airportCodeLocal = COLO_CODES_TO_AIRPORT_MAP.get(airportCode) ?? airportCode;
    const roleName =
      this.permission !== undefined ? `Ops@${this.permission}@${opts.stage}@${airportCodeLocal}` : this.clientRole;
    console.debug('Found service role name: ', roleName);
    const roleArn = getRoleArn(account.account_id, roleName);

    return new GroundstationAntennaLambda({
      credentials: buildAwsTemporaryCredentialProvider({
        roleArn,
        username: opts.username,
        masterCredentials: opts.credentials,
        region: opts.gsocRegion,
      }),
      region: account.region,
      endpoint: `https://${service.endpoint}`,
    });
  }

  private buildCapbilityDeviceClientForCell(opts: {
    credentials: AwsCredentialIdentity;
    username: string;
    stage: string;
    antenna: string;
    gsocRegion: string;
  }): GroundStationMgmtCellCapabilityDeviceConfigLambda {
    const account = this.getAntennaToAccountMap().get(opts.antenna);
    if (account === undefined) {
      throw new Error(`Cannot locate management cell account for ${opts.antenna} and ${opts.stage}`);
    }
    const service = account.services.find(service => service.service_key === 'capabilitydeviceconfig');
    if (service === undefined) {
      throw new Error(`Failed to locate service managementcell for ${account.account_id}`);
    }
    console.debug('Found endpoint: ', service.endpoint);

    // Quick way to get airport code for antennas
    const airportCode = account.cell_partition.substring(0, 3).toLowerCase();
    const airportCodeLocal = COLO_CODES_TO_AIRPORT_MAP.get(airportCode) ?? airportCode;
    const roleName =
      this.permission !== undefined ? `Ops@${this.permission}@${opts.stage}@${airportCodeLocal}` : this.clientRole;
    console.debug('Found service role name: ', roleName);
    const roleArn = getRoleArn(account.account_id, roleName);

    return new GroundStationMgmtCellCapabilityDeviceConfigLambda({
      credentials: buildAwsTemporaryCredentialProvider({
        roleArn,
        username: opts.username,
        masterCredentials: opts.credentials,
        region: opts.gsocRegion,
      }),
      region: account.region,
      endpoint: `https://${service.endpoint}`,
    });
  }
}
