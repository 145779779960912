// Allows queries using the short hand "+/-Xh" or "+/-Xd"
//  Set to default values otherwise

import { addDays, addHours, addMinutes, addSeconds } from 'date-fns';
import { Temporal } from '@js-temporal/polyfill';

export type RelativeTimeString = `${number}d` | `${number}h` | `${number}m` | `${number}s`;

export function isRelativeTimeString(timeString: unknown): timeString is RelativeTimeString {
  if (typeof timeString !== 'string') {
    return false;
  }
  const unit = timeString.slice(-1);
  const number = parseFloat(timeString.slice(0, -1));

  if (Number.isNaN(number) || !Number.isFinite(number)) {
    return false;
  }

  switch (unit) {
    case 'd':
    case 'h':
    case 'm':
    case 's':
      return true;
    default:
      return false;
  }
}

export function relativeTimeStringToDate(relativeTimeString: RelativeTimeString, initialDate = new Date()): Date {
  const unit = relativeTimeString.slice(-1);
  const number = parseFloat(relativeTimeString.slice(0, -1));

  switch (unit) {
    case 'd':
      return addDays(initialDate, number);
    case 'h':
      return addHours(initialDate, number);
    case 'm':
      return addMinutes(initialDate, number);
    case 's':
      return addSeconds(initialDate, number);
    default:
      throw new Error(
        `Error calculating relative Date: Value = ${relativeTimeString}. Format should be similar to 'Xh', 'Xd', 'Xm','Xs'`,
      );
  }
}

/** +00:00:00 */
export function formatElapsedTime(deltaMs: number): string {
  const sign = deltaMs >= 0 ? '+' : '-';
  if (deltaMs < 0) {
    deltaMs = -deltaMs;
  }
  const deltaS = Math.floor(deltaMs / 1000);
  let seconds = String(deltaS % 60);
  let minutes = String(Math.floor(deltaS / 60) % 60);
  let hours = String(Math.floor(deltaS / 3600));

  if (hours.length < 2) {
    hours = '0' + hours;
  }
  if (minutes.length < 2) {
    minutes = '0' + minutes;
  }
  if (seconds.length < 2) {
    seconds = '0' + seconds;
  }
  return sign + hours + ':' + minutes + ':' + seconds;
}

/** 0d1h20m from now/ago */
export function formatRelativeTime(deltaMs: number): string {
  const sign = deltaMs >= 0 ? 'from now' : 'ago';
  if (deltaMs < 0) {
    deltaMs = -deltaMs;
  }
  const deltaS = Math.floor(deltaMs / 1000);
  const minutes = pad(Math.floor(deltaS / 60) % 60, 2);
  const hours = pad(Math.floor(deltaS / 3600) % 24, 2);
  const days = Math.floor(deltaS / (24 * 3600)).toString();

  // Construct final string
  let returnString = '';
  if (days !== '' && days !== '0') {
    returnString += `${days}d`;
  }
  if (hours !== '' && hours !== '00') {
    returnString += `${hours}h`;
  }
  returnString += `${minutes}m`;
  returnString += ` ${sign}`;
  return returnString;
}

function pad(num: number, size: number) {
  return num.toString().padStart(size, '0');
}

/** Convert Date to YYYY-MM-DDTHH:MM:SS+00:00 */
export function toIso8601Date(date: Date): string {
  return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1, 2)}-${pad(date.getUTCDate(), 2)}T${pad(
    date.getUTCHours(),
    2,
  )}:${pad(date.getUTCMinutes(), 2)}:${pad(date.getUTCSeconds(), 2)}+00:00`;
}

export function isTimeRangeOverlapping(
  startTimeA: number,
  endTimeA: number,
  startTimeB: number,
  endTimeB: number,
): boolean {
  if (startTimeA > endTimeA)
    throw new Error(
      `Invalid time range provided for first time bound. Start Time: ${startTimeA} > End Time: ${endTimeA}`,
    );
  if (startTimeB > endTimeB)
    throw new Error(
      `Invalid time range provided for second time bound. Start Time: ${startTimeB} > End Time: ${endTimeB}`,
    );
  return startTimeA < endTimeB && endTimeA > startTimeB;
}

export function tryParseIsoDate<T>(item: T): Date | T {
  if (typeof item !== 'string') return item;
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(item)) return item;
  const date = new Date(item);
  if (date instanceof Date && date.toISOString() === item) {
    return date;
  }
  return item;
}

export function formatDateTimeWithTimezoneOffset(isoDate: string): string {
  // default the date to UTC.
  return isoDate !== '' ? isoDate + formatTimezoneOffset(isoDate, 0) : '';
}

function formatTimezoneOffset(isoDate: string, offsetInMinutes?: number): string {
  // Using default browser offset if not explicitly specified.
  offsetInMinutes = offsetInMinutes ?? 0 - new Date(isoDate).getTimezoneOffset();

  const sign = offsetInMinutes < 0 ? '-' : '+';
  const hoursOffset = Math.floor(Math.abs(offsetInMinutes) / 60)
    .toFixed(0)
    .padStart(2, '0');
  const minuteOffset = Math.abs(offsetInMinutes % 60)
    .toFixed(0)
    .padStart(2, '0');
  return `${sign}${hoursOffset}:${minuteOffset}`;
}

export function instantToDuration(instant: Temporal.Instant): Temporal.Duration {
  return Temporal.Now.instant()
    .until(instant)
    .round({ largestUnit: 'years', smallestUnit: 'seconds', relativeTo: Temporal.Now.zonedDateTimeISO() });
}

export function durationToInstant(duration: Temporal.Duration): Temporal.Instant {
  return Temporal.Now.instant().add(
    Temporal.Duration.from({
      milliseconds: duration
        .round({ largestUnit: 'years', smallestUnit: 'seconds', relativeTo: Temporal.Now.zonedDateTimeISO() })
        .total({ unit: 'milliseconds', relativeTo: Temporal.Now.zonedDateTimeISO() }),
    }),
  );
}

export function isRelative(value: Temporal.Instant | Temporal.Duration): value is Temporal.Duration {
  return value instanceof Temporal.Duration;
}

export function parseTimeRelativeOrAbsolute(value: string): Temporal.Duration | Temporal.Instant {
  try {
    return Temporal.Duration.from(value).round({
      largestUnit: 'year',
      smallestUnit: 'seconds',
      relativeTo: Temporal.Now.zonedDateTimeISO(),
    });
  } catch {
    return Temporal.Instant.from(value);
  }
}

export function durationOrInstantToDate(value: Temporal.Duration | Temporal.Instant): Date {
  if (isRelative(value)) {
    return new Date(durationToInstant(value).epochMilliseconds);
  }
  return new Date(value.epochMilliseconds);
}
