import type { DateRangePickerProps } from '@amzn/awsui-components-react';
import { DateRangePicker } from '@amzn/awsui-components-react';
import { addDays, addHours, addMinutes, addMonths, addSeconds, addWeeks, addYears } from 'date-fns';
import * as React from 'react';
import { toIso8601Date } from '../../utils/dateTimeUtilities';
import type { DateRange } from '../../hooks/useSearchParamDateRange';

/** A Date range picker */
export function GSOCDateRangePicker(props: {
  readonly onChange: (absoluteDateRange: DateRange) => void;
  readonly value?: DateRange;
  readonly initialValue?: {
    startDate: Date;
    endDate: Date;
  };
  readonly disabled?: boolean;
  readonly dateRangeExtents?: readonly [Date, Date];
  readonly durationExtentsSec?: readonly [number, number];
}) {
  const { initialValue, onChange, disabled, value } = props;

  if (value !== undefined && initialValue !== undefined) {
    throw new Error('Cannot supply initialValue to a controlled component');
  }

  // Internally tracked value for the date range picker
  const [internalDateRange, setInternalDateRange] = React.useState<DateRange | null>(initialValue ?? null);

  const dateRangeAsStrings = React.useMemo(() => {
    const dateValues = value ?? internalDateRange;

    if (dateValues === null) {
      return null;
    }
    return {
      type: 'absolute',
      startDate: toIso8601Date(dateValues.startDate),
      endDate: toIso8601Date(dateValues.endDate),
    } as DateRangePickerProps.AbsoluteValue;
  }, [internalDateRange, value]);

  return (
    <DateRangePicker
      placeholder='Select date'
      disabled={disabled}
      onChange={React.useCallback(
        ({ detail }: { detail: DateRangePickerProps.ChangeDetail }) => {
          if (detail.value !== null) {
            const absoluteRange = convertToAbsoluteRange(detail.value);
            if (value === undefined) {
              // if uncontrolled
              setInternalDateRange({
                startDate: new Date(absoluteRange.startDate),
                endDate: new Date(absoluteRange.endDate),
              });
            }

            onChange({
              startDate: new Date(absoluteRange.startDate),
              endDate: new Date(absoluteRange.endDate),
            });
          }
        },
        [onChange, value],
      )}
      value={dateRangeAsStrings}
      rangeSelectorMode='default'
      isValidRange={React.useCallback(
        value => {
          if (value !== null && (props.dateRangeExtents !== undefined || props.durationExtentsSec !== undefined)) {
            const absoluteValue = convertToAbsoluteRange(value);

            const startDate = new Date(absoluteValue.startDate);
            const endDate = new Date(absoluteValue.endDate);
            const durationSec = (endDate.getTime() - startDate.getTime()) / 1000;
            if (props.dateRangeExtents !== undefined) {
              const [min, max] = props.dateRangeExtents;
              if (startDate.getTime() < min.getTime() || endDate.getTime() > max.getTime()) {
                return {
                  valid: false,
                  errorMessage: `Date range must be between ${toIso8601Date(min)} and ${toIso8601Date(max)}`,
                };
              }
            }
            if (props.durationExtentsSec !== undefined) {
              const [min, max] = props.durationExtentsSec;

              if (durationSec < min || durationSec > max) {
                return {
                  valid: false,
                  errorMessage: `Duration must be between ${min.toLocaleString()} and ${max.toLocaleString()}`,
                };
              }
            }
          }
          return {
            valid: true,
          };
        },
        [props.dateRangeExtents, props.durationExtentsSec],
      )}
      getTimeOffset={() => 0}
      relativeOptions={RELATIVE_OPTIONS}
      i18nStrings={I18N_STRINGS}
    />
  );
}

const RELATIVE_OPTIONS = [
  {
    key: 'previous-3-hours',
    amount: 3,
    unit: 'hour',
    type: 'relative',
  },
  {
    key: 'previous-12-hours',
    amount: 12,
    unit: 'hour',
    type: 'relative',
  },
  {
    key: 'previous-7-days',
    amount: 7,
    unit: 'day',
    type: 'relative',
  },
  {
    key: 'next-7-days',
    amount: 7,
    unit: 'day',
    type: 'relative',
  },
  {
    key: 'plus-minus-12-hours',
    amount: 12,
    unit: 'hour',
    type: 'relative',
  },
  {
    key: 'plus-minus-3-days',
    amount: 3,
    unit: 'day',
    type: 'relative',
  },
] as const;

const I18N_STRINGS = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e: DateRangePickerProps.RelativeValue) => {
    const directionString = getRelativeTimeDirectionString(e);
    const t = e.amount === 1 ? e.unit : `${e.unit}s`;
    return `${directionString} ${e.amount} ${t}`;
  },
  formatUnit: (e, t) => (t === 1 ? e : `${e}s`),
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear and dismiss',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
} as DateRangePickerProps.I18nStrings;

// Have to have this funky redirection since we can't set custom properties on RelativeOption interface
// Have to derive these from the key
enum RelativeTimeDirection {
  PAST,
  FUTURE,
  PLUS_MINUS,
}

function getRelativeTimeDirection(range: DateRangePickerProps.RelativeValue) {
  // Custom relative range values don't have a key, and should default to PAST direction
  if (range.key === undefined || range.key.includes('previous')) {
    return RelativeTimeDirection.PAST;
  } else if (range.key.includes('next')) {
    return RelativeTimeDirection.FUTURE;
  } else if (range.key.includes('plus-minus')) {
    return RelativeTimeDirection.PLUS_MINUS;
  }
  console.warn('Unsupported relative time key, defaulting to PAST');
  return RelativeTimeDirection.PAST;
}

function convertRelativeTime(range: DateRangePickerProps.RelativeValue, initialTime: Date, isFuture: boolean): Date {
  // Add for future, subtract for past
  const amountToAdd = isFuture ? range.amount : -range.amount;
  switch (range.unit) {
    case 'second':
      return addSeconds(initialTime, amountToAdd);
    case 'minute':
      return addMinutes(initialTime, amountToAdd);
    case 'hour':
      return addHours(initialTime, amountToAdd);
    case 'day':
      return addDays(initialTime, amountToAdd);
    case 'week':
      return addWeeks(initialTime, amountToAdd);
    case 'month':
      return addMonths(initialTime, amountToAdd);
    case 'year':
      return addYears(initialTime, amountToAdd);
  }
}

function convertToAbsoluteRange(range: DateRangePickerProps.Value): DateRangePickerProps.AbsoluteValue {
  if (range.type === 'absolute') {
    return range;
  } else {
    const now = new Date();

    const direction = getRelativeTimeDirection(range);
    // Only convert time if selected direction requires it
    const start = [RelativeTimeDirection.PAST, RelativeTimeDirection.PLUS_MINUS].includes(direction)
      ? convertRelativeTime(range, now, false)
      : now;
    const end = [RelativeTimeDirection.FUTURE, RelativeTimeDirection.PLUS_MINUS].includes(direction)
      ? convertRelativeTime(range, now, true)
      : now;
    return {
      type: 'absolute',
      startDate: toIso8601Date(start),
      endDate: toIso8601Date(end),
    };
  }
}

function getRelativeTimeDirectionString(range: DateRangePickerProps.RelativeValue) {
  switch (getRelativeTimeDirection(range)) {
    case RelativeTimeDirection.PAST:
      return 'Last';
    case RelativeTimeDirection.FUTURE:
      return 'Next';
    case RelativeTimeDirection.PLUS_MINUS:
      return '+/-';
  }
}
