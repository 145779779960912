export type Pages = string;
/***
 * Define the pages and routing parameters needed.
 */
export const Pages = {
  HomePage: '/',
  Authorization: '/authorization',
  Contact: '/contact',
  ContactDetails: '/contact/:contactId',
  ContactList: '/contacts',
  MaintenanceList: '/maintenance',
  MaintenanceDetails: '/maintenance/:reservationId',
  MaintenanceSchedule: '/maintenanceSchedule',
  Oncall: '/oncall',
  SiteTimelines: '/siteTimelines',
  Site: '/site/:siteId',
  DataExplorer: '/dataExplorer',
  DataPlaneDashboard: '/dataPlaneDashboard',
  SNAP: `/snap`,
  SNAPSite: '/snap/:siteId/:deviceId?',
};

/**
 * Keeps our breadcrumbs for use elsewhere
 *
 * We have this as a class with only static methods because we can make them
 * getters and then will be re-evaluated on each access, which is important
 * for preserving the current location.search.
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class BREADCRUMBS {
  public static get Home() {
    return {
      text: 'Home',
      href: Pages.HomePage,
    };
  }

  public static get Oncall() {
    return {
      text: 'Oncall',
      href: Pages.Oncall,
    };
  }

  public static get Authorization() {
    return {
      text: 'Authorization',
      href: Pages.Authorization,
    };
  }

  public static get ListContacts() {
    return {
      text: 'List Contacts',
      href: Pages.ContactList,
    };
  }

  public static get MaintenanceList() {
    return {
      text: 'List Maintenance',
      href: Pages.MaintenanceList,
    };
  }

  public static MaintenanceDetails(reservationId: string) {
    return {
      text: reservationId,
      href: Pages.MaintenanceDetails,
    };
  }

  public static get MaintenanceSchedule() {
    return {
      text: 'Schedule Maintenance',
      href: Pages.MaintenanceSchedule + location.search,
    };
  }

  public static get SNAP() {
    return {
      text: 'Antenna Portal (SNAP)',
      href: Pages.SNAP,
    };
  }

  public static SNAPSite(siteId: string, deviceId: string | undefined) {
    const snapSite = [
      {
        text: siteId,
        href: Pages.SNAPSite,
      },
    ];
    if (deviceId !== undefined && deviceId !== '') {
      snapSite.push({
        text: deviceId,
        href: Pages.SNAPSite,
      });
    }
    return snapSite;
  }

  public static get SiteTimelines() {
    return {
      text: 'Site Timelines',
      href: Pages.SiteTimelines,
    };
  }

  public static Site(siteId: string) {
    return {
      text: siteId,
      href: Pages.Site.replace(':siteId', siteId),
    };
  }

  public static get ContactSearch() {
    return {
      text: 'View Individual Contact',
      href: Pages.Contact,
    };
  }

  public static ContactDetails(contactId: string) {
    return {
      text: contactId,
      href: Pages.Contact,
    };
  }

  public static get DataExplorer() {
    return {
      text: 'Data Explorer',
      href: Pages.DataExplorer,
    };
  }

  public static get DataPlaneDashboard() {
    return {
      text: 'Data Plane Dashboard',
      href: Pages.DataPlaneDashboard,
    };
  }
}
