import { ColumnLayout, Container, Header, LineChart } from '@amzn/awsui-components-react';
import { useMemo } from 'react';
import type { LfMetricsResultFragment } from '../../../../code-generated/graphqlOperations.generated';

interface LaminarFlowGraphsProps {
  readonly data: LfMetricsResultFragment[];
}

export function LaminarFlowGraphs(props: LaminarFlowGraphsProps) {
  return (
    <ColumnLayout columns={2}>
      <LaminarFlowLatencyGraph {...props} />
      <LaminarFlowLossGraph {...props} />
    </ColumnLayout>
  );
}

function LaminarFlowLossGraph(props: LaminarFlowGraphsProps) {
  const title = 'Laminar Flow Data Loss (Per Contact)';
  const [packetsLost, packetsLostPercent, xRangeMax, xRangeMin] = useMemo(() => {
    const totalPacketsLost: { x: Date; y: number }[] = [];
    const totalPacketsLostPercent: { x: Date; y: number }[] = [];
    let updatedXRangeMin: Date | undefined = undefined;
    let updatedXRangeMax: Date | undefined = undefined;
    for (const elem of props.data) {
      if (
        elem.timeStampStart == null ||
        elem.timeStampEnd == null ||
        elem.lfEgressStageMetricsV2?.metric__packets_lost_sequence_count?.total == null ||
        elem.lfEgressStageMetricsV2.metric__last_input_sequence_count?.max == null
      ) {
        continue;
      }
      const metricDate = new Date(elem.timeStampStart + 'Z');
      totalPacketsLost.push({
        x: metricDate,
        y: elem.lfEgressStageMetricsV2.metric__packets_lost_sequence_count.total,
      });
      totalPacketsLostPercent.push({
        x: metricDate,
        y:
          elem.lfEgressStageMetricsV2.metric__packets_lost_sequence_count.total /
            elem.lfEgressStageMetricsV2.metric__last_input_sequence_count.max || 0,
      });

      if (updatedXRangeMin == null || updatedXRangeMin > metricDate) {
        updatedXRangeMin = metricDate;
      }
      if (updatedXRangeMax == null || updatedXRangeMax < metricDate) {
        updatedXRangeMax = metricDate;
      }
    }
    return [totalPacketsLost, totalPacketsLostPercent, updatedXRangeMax, updatedXRangeMin];
  }, [props.data]);

  return (
    <Container header={<Header variant='h3'>{title}</Header>}>
      <LineChart
        xDomain={xRangeMin && xRangeMax ? [xRangeMin, xRangeMax] : undefined}
        xScaleType='time'
        xTitle='Time (UTC)'
        yTitle={title}
        series={[
          {
            title: 'Packets Lost Count',
            type: 'line',
            data: packetsLost,
          },
          {
            title: 'Packets Lost Percent',
            type: 'line',
            data: packetsLostPercent,
          },
        ]}
      />
    </Container>
  );
}

function LaminarFlowLatencyGraph(props: LaminarFlowGraphsProps) {
  const title = 'Laminar Flow Egress Latency (Per Contact) - Seconds';
  const [latencyMinAverage, latencyMaxAverage, xRangeMax, xRangeMin] = useMemo(() => {
    const latencyMinAverage: { x: Date; y: number }[] = [];
    const latencyMaxAverage: { x: Date; y: number }[] = [];
    let updatedXRangeMin: Date | undefined = undefined;
    let updatedXRangeMax: Date | undefined = undefined;
    for (const elem of props.data) {
      if (
        elem.timeStampStart == null ||
        elem.timeStampEnd == null ||
        elem.lfEgressStageMetricsV2?.metric__timestamp_delta_sec_max?.avg == null ||
        elem.lfEgressStageMetricsV2.metric__timestamp_delta_sec_min?.avg == null
      ) {
        continue;
      }
      const metricDate = new Date(elem.timeStampStart + 'Z');
      latencyMinAverage.push({ x: metricDate, y: elem.lfEgressStageMetricsV2.metric__timestamp_delta_sec_min.avg });
      latencyMaxAverage.push({ x: metricDate, y: elem.lfEgressStageMetricsV2.metric__timestamp_delta_sec_max.avg });

      if (updatedXRangeMin == null || updatedXRangeMin > metricDate) {
        updatedXRangeMin = metricDate;
      }
      if (updatedXRangeMax == null || updatedXRangeMax < metricDate) {
        updatedXRangeMax = metricDate;
      }
    }
    return [latencyMinAverage, latencyMaxAverage, updatedXRangeMax, updatedXRangeMin];
  }, [props.data]);

  return (
    <Container header={<Header variant='h3'>{title}</Header>}>
      <LineChart
        xDomain={xRangeMin && xRangeMax ? [xRangeMin, xRangeMax] : undefined}
        xScaleType='time'
        xTitle='Time (UTC)'
        yTitle={title}
        series={[
          {
            title: 'Latency Min Average',
            type: 'line',
            data: latencyMinAverage,
          },
          {
            title: 'Latency Max Average',
            type: 'line',
            data: latencyMaxAverage,
          },
        ]}
      />
    </Container>
  );
}
