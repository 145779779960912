import { debounce } from 'lodash';
import * as React from 'react';

const SET_PARAM_DEBOUNCE_TIME_MS = 50;

/** Read a string from URL Search Parameters */
export function useSearchParamState(key: string): [string, (value: string) => void];

/**
 * Store a string as state in URL Search Parameters
 *
 * Gives preference to already existing search parameter values on mount
 */
export function useSearchParamState(
  key: string,
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  initialValue?: string | (() => string),
): [string, React.Dispatch<React.SetStateAction<string>>];

/**
 * Store a piece of serializable data in URL Search Parameters
 *
 * Gives preference to already existing search parameter values on mount
 * Takes a serializer/deserializer pair to manage non-string values
 */
export function useSearchParamState<T>(
  key: string,
  initialValue: T | (() => T),
  serde: {
    serializer: (value: T) => string;
    deserializer: (value: string) => T;
  },
): [T, React.Dispatch<React.SetStateAction<T>>];

/**
 * Store state in URL Search Parameters
 *
 * If the query parameter already exists, it uses that value, if not, it uses
 * an initializer
 *
 * Takes a serializer/deserializer pair to manage non-string values
 */

export function useSearchParamState<T>(
  key: string,
  initialValue?: T | (() => T),
  options?: {
    serializer: (value: T) => string;
    deserializer: (value: string) => T;
  },
): [T, (value: T | ((prev: T | null) => T)) => void] {
  const debouncedSetParamValues = React.useMemo(() => debounce(setParamValues, SET_PARAM_DEBOUNCE_TIME_MS), []);
  const [value, setValue] = React.useState<T | null>(() => {
    const existingParamValue = getParamValue(key);
    if (existingParamValue !== null) {
      if (options !== undefined) {
        return options.deserializer(existingParamValue);
      }
      return existingParamValue as T;
    } else if (initialValue !== undefined) {
      if (typeof initialValue === 'function') {
        return (initialValue as () => T)();
      }
      return initialValue as T;
    }
    return null;
  });

  /** Sync value to parameters on change */
  React.useEffect(() => {
    if (value !== null) {
      const existingValue = getParamValue(key);
      const serializedValue = options !== undefined ? options.serializer(value) : (value as string);
      if (existingValue !== serializedValue) {
        debouncedSetParamValues({ [key]: serializedValue });
      }
    }
  }, [debouncedSetParamValues, key, options, value]);

  return [value as unknown as T, setValue];
}

export function useMultiSearchParamState<T extends Record<string, unknown>>(config: {
  [key in keyof T]: {
    initialValue?: T[key] | (() => T[key]);
    serde?: {
      serializer: (value: T[key]) => string;
      deserializer: (value: string) => T[key];
    };
    paramKey?: string;
  };
}) {
  const debouncedSetParamValues = React.useMemo(() => debounce(setParamValues, SET_PARAM_DEBOUNCE_TIME_MS), []);
  const [value, setValue] = React.useState<{ [key in keyof T]: T[key] | null }>(() => {
    const fullInitialValue: { [key in keyof T]: T[key] | null } = {} as T;
    for (const key in config) {
      const { initialValue, serde, paramKey } = config[key];
      const existingParamValue = getParamValue(paramKey ?? key);
      if (existingParamValue !== null) {
        if (serde !== undefined) {
          fullInitialValue[key] = serde.deserializer(existingParamValue);
        } else {
          fullInitialValue[key] = existingParamValue as T[typeof key];
        }
      } else if (initialValue !== undefined) {
        if (typeof initialValue === 'function') {
          fullInitialValue[key] = (initialValue as () => T[typeof key])();
        } else {
          fullInitialValue[key] = initialValue as T[typeof key];
        }
      } else {
        fullInitialValue[key] = null;
      }
    }
    return fullInitialValue;
  });

  /** Sync value to parameters on change */
  React.useEffect(() => {
    const convertedObj: { [key: string]: string } = {};
    for (const key in value) {
      const keyValue = value[key];
      if (keyValue === null) {
        continue;
      }
      const { serde, paramKey } = config[key];
      const existingValue = getParamValue(paramKey ?? key);
      const serializedValue = serde !== undefined ? serde.serializer(keyValue) : (keyValue as string);
      if (existingValue !== serializedValue) {
        convertedObj[paramKey ?? key] = serializedValue;
      }
    }
    if (Object.keys(convertedObj).length > 0) {
      debouncedSetParamValues(convertedObj);
    }
  }, [config, debouncedSetParamValues, value]);

  return [
    value as unknown as T,
    setValue as React.Dispatch<React.SetStateAction<{ [key in keyof T]: T[key] }>>,
  ] as const;
}

function getParamValue(param: string): string | null {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
}

function setParamValues(obj: { [paramKey: string]: string }) {
  const searchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of Object.entries(obj)) {
    searchParams.set(key, value);
  }
  window.history.replaceState(null, '', `${window.location.pathname}?${searchParams.toString()}`);
}
