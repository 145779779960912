import * as React from 'react';

import type { SideNavigationProps } from '@amzn/awsui-components-react';
import { SideNavigation } from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';
import { BREADCRUMBS } from '../pages/pages';
import { getSites } from '../utils/accountsUtils';
import { useAppSettings } from '../context/AppSettings';
import { useDoesUserHavePermissionClaim } from '../context/Permissions';
import { PermissionClaim } from '../code-generated/PermissionClaim.generated';

export function NavigationSidebar() {
  const history = useHistory();
  const { stage } = useAppSettings();
  const canReadSiteHealthAndStatus = useDoesUserHavePermissionClaim(PermissionClaim.ReadSiteHealthAndStatus);

  return (
    <SideNavigation
      items={NavigationItems(stage, canReadSiteHealthAndStatus === true)}
      onFollow={React.useCallback(
        (ev: CustomEvent<SideNavigationProps.FollowDetail>) => {
          ev.preventDefault();
          if (ev.detail.href) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            history.push(ev.detail.href);
          }
        },
        [history],
      )}
      activeHref={history.location.pathname}
    />
  );
}

function NavigationItems(stage: string, canReadSiteHealthAndStatus: boolean): readonly SideNavigationProps.Item[] {
  return [
    { type: 'link', ...BREADCRUMBS.Home },
    { type: 'link', ...BREADCRUMBS.Oncall },
    {
      type: 'link',
      ...BREADCRUMBS.Authorization,
    },
    {
      type: 'section',
      text: 'Contacts',
      items: [
        {
          type: 'link',
          ...BREADCRUMBS.ListContacts,
        },
        {
          type: 'link',
          ...BREADCRUMBS.ContactSearch,
        },
      ],
    },
    {
      type: 'section',
      text: 'Site Management',
      items: [
        {
          type: 'link',
          ...BREADCRUMBS.SiteTimelines,
        },
        {
          type: 'link',
          ...BREADCRUMBS.MaintenanceList,
        },
        {
          type: 'link',
          ...BREADCRUMBS.MaintenanceSchedule,
        },
        {
          type: 'link',
          ...BREADCRUMBS.SNAP,
        },
      ],
    },
    {
      type: 'section',
      text: 'Dashboards',
      items: [
        {
          type: 'link',
          ...BREADCRUMBS.DataPlaneDashboard,
        },
      ],
    },
    ...(canReadSiteHealthAndStatus
      ? ([
          {
            type: 'section',
            text: 'Site Status',
            items: getSites(stage).map(site => ({
              type: 'link',
              ...BREADCRUMBS.Site(site.groundStationId),
            })),
          },
        ] as readonly SideNavigationProps.Item[])
      : []),
    {
      type: 'section',
      text: 'Dev Tools',
      items: [
        {
          type: 'link',
          ...BREADCRUMBS.DataExplorer,
        },
      ],
    },
  ];
}
