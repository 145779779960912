import { Alert, Link, Spinner } from '@amzn/awsui-components-react';
import type { PermissionClaim } from '../code-generated/PermissionClaim.generated';
import { NOT_LOADED, useDoesUserHavePermissionClaims } from '../context/Permissions';
import * as tokens from '@amzn/awsui-design-tokens/polaris.js';
import { styled } from '@linaria/react';

export function RequiresPermissionClaims(props: {
  readonly claims: PermissionClaim[];
  readonly operator?: 'and' | 'or';
  readonly children: React.ReactNode | React.ReactNode[];
}) {
  const hasClaims = useDoesUserHavePermissionClaims(props.claims, props.operator);
  return hasClaims === NOT_LOADED ? (
    <CenteredMessage>
      <Spinner size='large' /> Loading permission claims...
    </CenteredMessage>
  ) : hasClaims ? (
    props.children
  ) : (
    <Alert type='warning'>
      You must have {props.operator === 'and' ? 'all' : 'at least one'} of the necessary permission claims{' '}
      <code>[{props.claims.join(', ')}]</code> for this content to be useful. If you feel you need these permissions,
      please{' '}
      <Link external href='https://t.corp.amazon.com/create/templates/86aab676-aee1-4f86-b39c-f8f5f145188e'>
        cut a ticket
      </Link>
    </Alert>
  );
}

export const CenteredMessage = styled.div`
  background-color: ${tokens.colorBackgroundLayoutMain};
  color: ${tokens.colorTextBodyDefault};
  font-family: ${tokens.fontFamilyBase};
  /* height: 100vh; */
  /* height: 100vh;
  width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
