export enum PermissionClaim {
  RequestContact = 'RequestContact',
  DiscoverContact = 'DiscoverContact',
  DiscoverIamRole = 'DiscoverIamRole',
  ReadCPEOnboarding = 'ReadCPEOnboarding',
  ReadMaintenance = 'ReadMaintenance',
  ScheduleMaintenance = 'ScheduleMaintenance',
  ReadCapabilityManagement = 'ReadCapabilityManagement',
  DiscoverOnboardingValidation = 'DiscoverOnboardingValidation',
  ReadSiteHealthAndStatus = 'ReadSiteHealthAndStatus',
  RequestOnboardingValidation = 'RequestOnboardingValidation',
  ReadSatelliteOnboardingUplinkApprovals = 'ReadSatelliteOnboardingUplinkApprovals',
  ReadContactIdentifyingInformation = 'ReadContactIdentifyingInformation',
  ReadSiteAntennaMetrics = 'ReadSiteAntennaMetrics',
}
