export const typeDefs = `

scalar AWSDateTime

input AWSDateTimeFilterInput {
  _eq: AWSDateTime
  _exists: Boolean
  _gt: AWSDateTime
  _gte: AWSDateTime
  _in: [AWSDateTime!]
  _lt: AWSDateTime
  _lte: AWSDateTime
  _neq: AWSDateTime
  _nin: [AWSDateTime!]
}

scalar AWSJSON

type AgentPostPassReport {
  agentId: String!
  markdownText: String!
  s3Key: String!
  taskDocumentId: String!
}

type AgentReport {
  agentId: String!
  taskReports: [TaskReport!]!
}

type Aggregation {
  _avg: Float!
  _max: Float!
  _min: Float!
  _p1: Float!
  _p5: Float!
  _p25: Float!
  _p50: Float!
  _p75: Float!
  _p95: Float!
  _p99: Float!
  _stdDev: Float!
  _sum: Float!
}

enum AngleUnits {
  DEGREE_ANGLE
  RADIAN
}

input BooleanFilterInput {
  _eq: Boolean
  _exists: Boolean
  _neq: Boolean
}

type BusinessInsightsContact {
  antennaName: String
  contactId: String!
  contactStatus: ContactStatus!
  customerId: String!
  dataDeliveryRegions: [String!]
  dataflowList: [Dataflow!]
  endTime: AWSDateTime!
  groundStationAirportCode: String
  groundStationName: String
  maximumElevation: Elevation
  missionProfileArn: String
  postPassEndTime: AWSDateTime
  prePassStartTime: AWSDateTime
  reservationArns: [String!]
  satelliteArn: String
  schedulingRegion: String
  startTime: AWSDateTime!
}

enum ConfigCapabilityType {
  ANTENNA_DOWNLINK
  ANTENNA_DOWNLINK_DEMOD_DECODE
  ANTENNA_UPLINK
  CONNECTION_PROXY
  DATAFLOW_ENDPOINT
  MANAGED_ANTENNA_DOWNLINK_DEMOD_DECODE
  S3_PLAYBACK
  S3_RECORDING
  TRACKING
  UPLINK_ECHO
}

type Contact {
  agentPostPassReports: [AgentPostPassReport!]
  id: String!
  images: [PostPassImage!]
  visualization: ContactVisualization
}

input ContactByIdInput {
  id: String!
}

type ContactGlobalDDBTableV2 {
  awsAccountId: String!
  cancellationReason: String
  cancellationTimestamp: AWSDateTime
  capabilitiesToTest: [String!]
  configIdToDagNodeMap: AWSJSON
  contactEndTimeEpochSeconds: AWSDateTime!
  contactId: String!
  contactStartTimeEpochSeconds: AWSDateTime!
  contactStatus: ContactStatus!
  contactType: ContactType!
  createReservationForRegionSignatureMaps: String
  createTimestamp: AWSDateTime!
  createdInRegion: String!
  failedToScheduleReasons: [String!]
  groundStationId: String!
  maxElevationDegrees: Float!
  meteringArchiveId: String
  meteringStatus: MeteringStatus
  meteringUsage: MeteringUsage
  migrationVersion: Int
  missionProfileArn: String!
  obsoleteReservationArnList: [String!]
  payerToken: String
  postPassEndTimeEpochSeconds: AWSDateTime!
  prePassStartTimeEpochSeconds: AWSDateTime!
  reschedulingTimestamp: AWSDateTime
  reservationArnList: [String!]
  reserveContactStepFunctionId: String!
  satelliteArn: String!
  signatureMap: String
  targetRegion: String!
  updateTimestamp: AWSDateTime
  versionNumber: Int!
  versionTimestamp: AWSDateTime
}

enum ContactStatus {
  AVAILABLE
  AWS_CANCELLED
  AWS_FAILED
  CANCELLED
  CANCELLING
  COMPLETED
  FAILED
  FAILED_TO_SCHEDULE
  PASS
  POSTPASS
  PREPASS
  SCHEDULED
  SCHEDULING
}

type ContactTable {
  businessInsightsContact: BusinessInsightsContact
  contactGlobalDDBTableV2Dto: ContactGlobalDDBTableV2
  datalakeTtl: AWSDateTime!
  inRegionContactDdbTable: InRegionContactDdbTable
  pk: String!
  sk: String!
  version: Int
}

enum ContactType {
  ANTENNA_SPECIFIC
  GROUND_STATION_SPECIFIC
}

type ContactVisualization {
  dataplaneSvgUrlDark: String
  dataplaneSvgUrlLight: String
  s3Key: String
}

type CpContactEvent010 {
  contactId: String!
  contactStatus: String!
  customerNamePart: String!
}

enum CpCriticality {
  PREFERRED
  REMOVED
  REQUIRED
}

enum CpDataflowType {
  DDS
  DDS_V2
}

type CursorPart {
  key: String!
  value: String!
}

input CursorPartInput {
  key: String!
  value: String!
}

type DDXStatistic {
  avg: Float
  median: Float
  p50: Float
  p75: Float
  p90: Float
  p95: Float
  p99: Float
  stdDev: Float
}

input DDXStatisticFilterInput {
  _and: [DDXStatisticFilterInput!]
  _exists: Boolean
  _not: DDXStatisticFilterInput
  _or: [DDXStatisticFilterInput!]
  avg: FloatFilterInput
  median: FloatFilterInput
  p50: FloatFilterInput
  p75: FloatFilterInput
  p90: FloatFilterInput
  p95: FloatFilterInput
  p99: FloatFilterInput
  stdDev: FloatFilterInput
}

type DDXStreamMetric {
  dataLatency: DDXStatistic
  dataPacketsReceived: Int
  dataRate: DDXStatistic
  streamDirection: String!
  streamId: String!
  streamName: String!
  wanLatency: DDXStatistic
  wanOverhead: DDXStatistic
  wanPacketsDropped: Int
  wanPacketsDuplicated: Int
  wanPacketsLost: Int
  wanPacketsReceived: Int
  wanPacketsReordered: Int
  wanPacketsRepaired: Int
  wanRate: DDXStatistic
  wanRtt: DDXStatistic
}

input DDXStreamMetricFilterInput {
  _and: [DDXStreamMetricFilterInput!]
  _exists: Boolean
  _not: DDXStreamMetricFilterInput
  _or: [DDXStreamMetricFilterInput!]
  dataLatency: DDXStatisticFilterInput
  dataPacketsReceived: IntegerFilterInput
  dataRate: DDXStatisticFilterInput
  streamDirection: StringFilterInput
  streamId: StringFilterInput
  streamName: StringFilterInput
  wanLatency: DDXStatisticFilterInput
  wanOverhead: DDXStatisticFilterInput
  wanPacketsDropped: IntegerFilterInput
  wanPacketsDuplicated: IntegerFilterInput
  wanPacketsLost: IntegerFilterInput
  wanPacketsReceived: IntegerFilterInput
  wanPacketsReordered: IntegerFilterInput
  wanPacketsRepaired: IntegerFilterInput
  wanRate: DDXStatisticFilterInput
  wanRtt: DDXStatisticFilterInput
}

type DagNode {
  configId: String!
  dagNodeSemanticVersion: String
  dataflowEndpointDetails: String
  dataflowSourceConfigId: String
  dataflowUuid: String
  executionContextId: String
  matchedCapabilityArns: [String!]
  nodeConfigCapabilityType: ConfigCapabilityType
  outputTypeToNextConfigIdsMap: AWSJSON
  outputTypeToPreviousConfigIdsMap: AWSJSON
  region: String
  reservationId: String
  reservationStatus: ReservationStatus
  reservedCapabilityArn: String
  s3RecordingDetails: String
  sameReservationGroupAsConfigIds: [String!]
  vita49StreamId: Int
}

type DataPlaneDDXMetric {
  accountId: String!
  contactId: String!
  endTime: String!
  jobId: String!
  postPassEndTime: String!
  prePassStartTime: String!
  region: String!
  startTime: String!
  streamMetrics: [DDXStreamMetric!]!
}

input DataPlaneDDXMetricFilterInput {
  _and: [DataPlaneDDXMetricFilterInput!]
  _exists: Boolean
  _not: DataPlaneDDXMetricFilterInput
  _or: [DataPlaneDDXMetricFilterInput!]
  accountId: StringFilterInput
  contactId: StringFilterInput
  endTime: StringFilterInput
  jobId: StringFilterInput
  postPassEndTime: StringFilterInput
  prePassStartTime: StringFilterInput
  region: StringFilterInput
  startTime: StringFilterInput
  streamMetrics: DDXStreamMetricFilterInput
}

input DataPlaneDDXMetricSortInput {
  accountId: SortOrder
  contactId: SortOrder
  endTime: SortOrder
  jobId: SortOrder
  postPassEndTime: SortOrder
  prePassStartTime: SortOrder
  region: SortOrder
  startTime: SortOrder
}

type DataPlaneLFMetric {
  agent_id: String!
  contact_id: String!
  dataflow_id: String!
  id: String!
  lfDecryptStageMetricsV2: LfDecryptStageMetricsV2
  lfEgressStageMetricsV2: LfEgressStageMetricsV2
  lfEncryptStageMetricsV2: LfEncryptStageMetricsV2
  lfIngressStageMetricsV2: LfIngressStageMetricsV2
  lfStripeDecodeStageMetricsV2: LfStripeDecodeStageMetricsV2
  lfStripeEncodeStageMetricsV2: LfStripeEncodeStageMetricsV2
  lfTimeDecodeStageMetricsV2: LfTimeDecodeStageMetricsV2
  lfTimeEncodeStageMetricsV2: LfTimeEncodeStageMetricsV2
  lfTracerouteStageMetricsV2: [LfTracerouteStageMetricsV2!]
  lfWanRxStageMetricsV2: LfWanRxStageMetricsV2
  lfWanTxStageMetricsV2: LfWanTxStageMetricsV2
  rpm_version: String!
  task_id: String!
  timeStampEnd: String
  timeStampStart: String
}

type DataPlaneLFMetricAggregation {
  lfDecryptStageMetricsV2: LfDecryptStageMetricsV2Aggregation!
  lfEgressStageMetricsV2: LfEgressStageMetricsV2Aggregation!
  lfEncryptStageMetricsV2: LfEncryptStageMetricsV2Aggregation!
  lfIngressStageMetricsV2: LfIngressStageMetricsV2Aggregation!
  lfStripeDecodeStageMetricsV2: LfStripeDecodeStageMetricsV2Aggregation!
  lfStripeEncodeStageMetricsV2: LfStripeEncodeStageMetricsV2Aggregation!
  lfTimeDecodeStageMetricsV2: LfTimeDecodeStageMetricsV2Aggregation!
  lfTimeEncodeStageMetricsV2: LfTimeEncodeStageMetricsV2Aggregation!
  lfWanRxStageMetricsV2: LfWanRxStageMetricsV2Aggregation!
  lfWanTxStageMetricsV2: LfWanTxStageMetricsV2Aggregation!
}

input DataPlaneLFMetricFilterInput {
  _and: [DataPlaneLFMetricFilterInput!]
  _exists: Boolean
  _not: DataPlaneLFMetricFilterInput
  _or: [DataPlaneLFMetricFilterInput!]
  agent_id: StringFilterInput
  contact_id: StringFilterInput
  dataflow_id: StringFilterInput
  id: StringFilterInput
  lfDecryptStageMetricsV2: LfDecryptStageMetricsV2FilterInput
  lfEgressStageMetricsV2: LfEgressStageMetricsV2FilterInput
  lfEncryptStageMetricsV2: LfEncryptStageMetricsV2FilterInput
  lfIngressStageMetricsV2: LfIngressStageMetricsV2FilterInput
  lfStripeDecodeStageMetricsV2: LfStripeDecodeStageMetricsV2FilterInput
  lfStripeEncodeStageMetricsV2: LfStripeEncodeStageMetricsV2FilterInput
  lfTimeDecodeStageMetricsV2: LfTimeDecodeStageMetricsV2FilterInput
  lfTimeEncodeStageMetricsV2: LfTimeEncodeStageMetricsV2FilterInput
  lfTracerouteStageMetricsV2: LfTracerouteStageMetricsV2FilterInput
  lfWanRxStageMetricsV2: LfWanRxStageMetricsV2FilterInput
  lfWanTxStageMetricsV2: LfWanTxStageMetricsV2FilterInput
  rpm_version: StringFilterInput
  task_id: StringFilterInput
  timeStampEnd: StringFilterInput
  timeStampStart: StringFilterInput
}

input DataPlaneLFMetricSortInput {
  agent_id: SortOrder
  contact_id: SortOrder
  dataflow_id: SortOrder
  id: SortOrder
  lfDecryptStageMetricsV2: LfDecryptStageMetricsV2SortInput
  lfEgressStageMetricsV2: LfEgressStageMetricsV2SortInput
  lfEncryptStageMetricsV2: LfEncryptStageMetricsV2SortInput
  lfIngressStageMetricsV2: LfIngressStageMetricsV2SortInput
  lfStripeDecodeStageMetricsV2: LfStripeDecodeStageMetricsV2SortInput
  lfStripeEncodeStageMetricsV2: LfStripeEncodeStageMetricsV2SortInput
  lfTimeDecodeStageMetricsV2: LfTimeDecodeStageMetricsV2SortInput
  lfTimeEncodeStageMetricsV2: LfTimeEncodeStageMetricsV2SortInput
  lfWanRxStageMetricsV2: LfWanRxStageMetricsV2SortInput
  lfWanTxStageMetricsV2: LfWanTxStageMetricsV2SortInput
  rpm_version: SortOrder
  task_id: SortOrder
  timeStampEnd: SortOrder
  timeStampStart: SortOrder
}

type Dataflow {
  dataflowId: String
  dataflowMetrics: DataflowMetrics
  destinationNode: DataflowNode
  rfCharacteristics: RfCharacteristics
  sourceNode: DataflowNode
  tags: [String!]
  vita49StreamCharacteristics: Vita49StreamCharacteristics
}

type DataflowMetrics {
  bytesReceived: Long
  bytesTransferred: Long
}

type DataflowNode {
  nodeArn: String
  nodeRegion: String
  nodeType: ConfigCapabilityType
}

type DataflowTranslation {
  egress: TranslationDetail
  ingress: TranslationDetail
}

input DataflowTranslationFilterInput {
  _and: [DataflowTranslationFilterInput!]
  _exists: Boolean
  _not: DataflowTranslationFilterInput
  _or: [DataflowTranslationFilterInput!]
  egress: TranslationDetailFilterInput
  ingress: TranslationDetailFilterInput
}

enum DeliveryMechanism {
  DDX
  LAMINAR_FLOW
}

type DereferencedMissionProfileData {
  createdInRegion: String
  customerAwsAccountId: String
  minimumViableContactSeconds: Int
  missionProfileArn: String
  missionProfileId: String
  name: String
  postPassDurationSeconds: Int
  prePassDurationSeconds: Int
}

input EchoInput {
  input: String!
}

type EchoOutput {
  output: String!
}

type Elevation {
  unit: AngleUnits!
  value: Float!
}

input FloatFilterInput {
  _eq: Float
  _exists: Boolean
  _gt: Float
  _gte: Float
  _in: [Float!]
  _lt: Float
  _lte: Float
  _neq: Float
  _nin: [Float!]
}

type Frequency {
  units: String
  value: Float
}

enum FrequencyBand {
  KA_BAND
  NONE
  S_BAND
  X_BAND
}

input GetDataPlaneDDXMetricInput {
  jobId: String
}

input GetDataPlaneLFMetricInput {
  id: String
}

input GetGsocContactDetailsInput {
  contactId: String
}

type GsocAntennaDemodDecodeDetails {
  decodeConfigData: GsocDecodeConfigData!
  demodulationConfigData: GsocDemodulationConfigData!
  rfCharacteristics: GsocRfCharacteristics!
}

input GsocAntennaDemodDecodeDetailsFilterInput {
  _and: [GsocAntennaDemodDecodeDetailsFilterInput!]
  _exists: Boolean
  _not: GsocAntennaDemodDecodeDetailsFilterInput
  _or: [GsocAntennaDemodDecodeDetailsFilterInput!]
  decodeConfigData: GsocDecodeConfigDataFilterInput
  demodulationConfigData: GsocDemodulationConfigDataFilterInput
  rfCharacteristics: GsocRfCharacteristicsFilterInput
}

type GsocAntennaDownlinkDetails {
  rfCharacteristics: GsocRfCharacteristics!
}

input GsocAntennaDownlinkDetailsFilterInput {
  _and: [GsocAntennaDownlinkDetailsFilterInput!]
  _exists: Boolean
  _not: GsocAntennaDownlinkDetailsFilterInput
  _or: [GsocAntennaDownlinkDetailsFilterInput!]
  rfCharacteristics: GsocRfCharacteristicsFilterInput
}

type GsocAwsTemporaryCredentials {
  accessKeyId: String!
  expiration: AWSDateTime!
  secretAccessKey: String!
  sessionToken: String!
}

type GsocCapability {
  arn: String
  id: String!
  images: [NamedImage!]!
  type: String!
}

input GsocCapabilityFilterInput {
  _and: [GsocCapabilityFilterInput!]
  _exists: Boolean
  _not: GsocCapabilityFilterInput
  _or: [GsocCapabilityFilterInput!]
  arn: StringFilterInput
  id: StringFilterInput
  type: StringFilterInput
}

type GsocConfig {
  arn: String
  id: String!
  type: String!
}

input GsocConfigFilterInput {
  _and: [GsocConfigFilterInput!]
  _exists: Boolean
  _not: GsocConfigFilterInput
  _or: [GsocConfigFilterInput!]
  arn: StringFilterInput
  id: StringFilterInput
  type: StringFilterInput
}

type GsocConnectionProxyDetails {
  configArn: String
  dataflowTranslation: DataflowTranslation
}

input GsocConnectionProxyDetailsFilterInput {
  _and: [GsocConnectionProxyDetailsFilterInput!]
  _exists: Boolean
  _not: GsocConnectionProxyDetailsFilterInput
  _or: [GsocConnectionProxyDetailsFilterInput!]
  configArn: StringFilterInput
  dataflowTranslation: DataflowTranslationFilterInput
}

type GsocContactDetails {
  acuImage: String
  agentReports: [AgentReport!]
  contactId: String!
  contactStatus: ContactStatus!
  contactVisualization: String
  customer: GsocCustomer!
  dataflows: [GsocDataflow!]
  datalakeTtl: AWSDateTime
  destinationRegions: [String!]!
  groundStations: [GsocGroundStation!]
  metadata: AWSJSON
  missionProfile: GsocMissionProfile!
  pk: String!
  satellite: GsocSatellite!
  schedulingRegion: String!
  tags: [String!]
  timeRange: TimeRange!
}

type GsocContactDetailsAggregation {
  satellite: GsocSatelliteAggregation!
}

input GsocContactDetailsFilterInput {
  _and: [GsocContactDetailsFilterInput!]
  _exists: Boolean
  _not: GsocContactDetailsFilterInput
  _or: [GsocContactDetailsFilterInput!]
  contactId: StringFilterInput
  contactStatus: StringFilterInput
  customer: GsocCustomerFilterInput
  dataflows: GsocDataflowFilterInput
  datalakeTtl: TimestampFilterInput
  destinationRegions: StringFilterInput
  groundStations: GsocGroundStationFilterInput
  missionProfile: GsocMissionProfileFilterInput
  pk: StringFilterInput
  satellite: GsocSatelliteFilterInput
  schedulingRegion: StringFilterInput
  tags: StringFilterInput
  timeRange: TimeRangeFilterInput
}

input GsocContactDetailsSortInput {
  contactId: SortOrder
  contactStatus: SortOrder
  customer: GsocCustomerSortInput
  datalakeTtl: SortOrder
  missionProfile: GsocMissionProfileSortInput
  pk: SortOrder
  satellite: GsocSatelliteSortInput
  schedulingRegion: SortOrder
  timeRange: TimeRangeSortInput
}

type GsocContactDynamoDbTable {
  contactId: String!
  contactStatus: ContactStatus!
  customer: GsocCustomer!
  dataflows: [GsocDataflow!]
  datalakeTtl: AWSDateTime
  destinationRegions: [String!]!
  groundStations: [GsocGroundStation!]
  missionProfile: GsocMissionProfile!
  pk: String!
  satellite: GsocSatellite!
  schedulingRegion: String!
  tags: [String!]
  timeRange: TimeRange!
}

type GsocContactEvent010 {
  contactId: String
}

type GsocContactIndexOpenSearch {
  contactId: String!
  contactStatus: ContactStatus!
  customer: GsocCustomer!
  dataflows: [GsocDataflow!]
  datalakeTtl: AWSDateTime
  destinationRegions: [String!]!
  groundStations: [GsocGroundStation!]
  missionProfile: GsocMissionProfile!
  pk: String!
  satellite: GsocSatellite!
  schedulingRegion: String!
  tags: [String!]
  timeRange: TimeRange!
}

input GsocContactStatusFilterInput {
  _eq: ContactStatus
  _exists: Boolean
  _in: [ContactStatus!]
  _neq: ContactStatus
  _nin: [ContactStatus!]
}

type GsocCustomer {
  awsAccountId: String
  awsGsaasAwsAccountNamePart: String!
  external: Boolean!
}

input GsocCustomerFilterInput {
  _and: [GsocCustomerFilterInput!]
  _exists: Boolean
  _not: GsocCustomerFilterInput
  _or: [GsocCustomerFilterInput!]
  awsAccountId: GsocStringFilterInput
  awsGsaasAwsAccountNamePart: GsocStringFilterInput
  external: BooleanFilterInput
}

input GsocCustomerSortInput {
  awsAccountId: SortOrder
  awsGsaasAwsAccountNamePart: SortOrder
  external: SortOrder
}

type GsocDataflow {
  dataflowId: String!
  destination: GsocNode!
  source: GsocNode!
  tags: [String!]!
}

input GsocDataflowFilterInput {
  _and: [GsocDataflowFilterInput!]
  _exists: Boolean
  _not: GsocDataflowFilterInput
  _or: [GsocDataflowFilterInput!]
  dataflowId: StringFilterInput
  destination: GsocNodeFilterInput
  source: GsocNodeFilterInput
  tags: StringFilterInput
}

enum GsocDataplaneType {
  DATA_DEFENDER
  LAMINAR_FLOW
}

type GsocDecodeConfigData {
  unvalidatedJson: String!
}

input GsocDecodeConfigDataFilterInput {
  _and: [GsocDecodeConfigDataFilterInput!]
  _exists: Boolean
  _not: GsocDecodeConfigDataFilterInput
  _or: [GsocDecodeConfigDataFilterInput!]
  unvalidatedJson: StringFilterInput
}

type GsocDemodulationConfigData {
  unvalidatedJson: String!
}

input GsocDemodulationConfigDataFilterInput {
  _and: [GsocDemodulationConfigDataFilterInput!]
  _exists: Boolean
  _not: GsocDemodulationConfigDataFilterInput
  _or: [GsocDemodulationConfigDataFilterInput!]
  unvalidatedJson: StringFilterInput
}

input GsocGetContactDetailsInput {
  date: AWSDateTime
  id: String!
}

type GsocGroundStation {
  antennaName: String
  groundStationId: String!
  groundStationName: String!
  region: String!
}

input GsocGroundStationFilterInput {
  _and: [GsocGroundStationFilterInput!]
  _exists: Boolean
  _not: GsocGroundStationFilterInput
  _or: [GsocGroundStationFilterInput!]
  antennaName: GsocStringFilterInput
  groundStationId: GsocStringFilterInput
  groundStationName: GsocStringFilterInput
  region: GsocStringFilterInput
}

type GsocIamRole {
  awsTemporaryCredentials: GsocAwsTemporaryCredentials
  isAuthorized: Boolean!
  name: String!
  roleArn: String!
}

type GsocIntegerRange {
  maximum: Int!
  minimum: Int!
}

input GsocIntegerRangeFilterInput {
  _and: [GsocIntegerRangeFilterInput!]
  _exists: Boolean
  _not: GsocIntegerRangeFilterInput
  _or: [GsocIntegerRangeFilterInput!]
  maximum: IntegerFilterInput
  minimum: IntegerFilterInput
}

input GsocListContactsPaginatedInput {
  cursor: [String!]
  limit: Int = 100
  sort: [ListGsocContactsSortInput!]
  where: ListGsocContactsFilterInput
}

type GsocListContactsPaginatedOutput {
  contacts: [GsocContactDetails!]!
  pageInfo: GsocPageInfo!
}

type GsocListIamRolesOutput {
  roles: [GsocIamRole!]!
}

type GsocManagedAntennaDemodDecodeDetails {
  managedRadioConfigId: String!
  rfCharacteristics: GsocRfCharacteristics!
}

input GsocManagedAntennaDemodDecodeDetailsFilterInput {
  _and: [GsocManagedAntennaDemodDecodeDetailsFilterInput!]
  _exists: Boolean
  _not: GsocManagedAntennaDemodDecodeDetailsFilterInput
  _or: [GsocManagedAntennaDemodDecodeDetailsFilterInput!]
  managedRadioConfigId: StringFilterInput
  rfCharacteristics: GsocRfCharacteristicsFilterInput
}

type GsocMissionProfile {
  missionProfileArn: String
  missionProfileId: String!
  missionProfileName: String
}

input GsocMissionProfileFilterInput {
  _and: [GsocMissionProfileFilterInput!]
  _exists: Boolean
  _not: GsocMissionProfileFilterInput
  _or: [GsocMissionProfileFilterInput!]
  missionProfileArn: GsocStringFilterInput
  missionProfileId: GsocStringFilterInput
  missionProfileName: GsocStringFilterInput
}

input GsocMissionProfileSortInput {
  missionProfileArn: SortOrder
  missionProfileId: SortOrder
  missionProfileName: SortOrder
}

type GsocNode {
  capability: GsocCapability!
  config: GsocConfig!
  nodeDetails: GsocNodeDetails!
  region: String!
}

type GsocNodeAgentDetails {
  version: String
}

input GsocNodeAgentDetailsFilterInput {
  _and: [GsocNodeAgentDetailsFilterInput!]
  _exists: Boolean
  _not: GsocNodeAgentDetailsFilterInput
  _or: [GsocNodeAgentDetailsFilterInput!]
  version: StringFilterInput
}

type GsocNodeDataplaneDataDefenderDetails {
  dataDeliveryServiceDestinationArn: String
  dataDeliveryServiceSourceArn: String
  securityDetails: GsocSecurityDetails
  vita49StreamId: Int
}

input GsocNodeDataplaneDataDefenderDetailsFilterInput {
  _and: [GsocNodeDataplaneDataDefenderDetailsFilterInput!]
  _exists: Boolean
  _not: GsocNodeDataplaneDataDefenderDetailsFilterInput
  _or: [GsocNodeDataplaneDataDefenderDetailsFilterInput!]
  dataDeliveryServiceDestinationArn: StringFilterInput
  dataDeliveryServiceSourceArn: StringFilterInput
  securityDetails: GsocSecurityDetailsFilterInput
  vita49StreamId: IntegerFilterInput
}

type GsocNodeDataplaneDetails {
  dataDefender: GsocNodeDataplaneDataDefenderDetails
  dataflowType: GsocDataplaneType
  laminarFlow: GsocNodeDataplaneLaminarFlowDetails
}

input GsocNodeDataplaneDetailsFilterInput {
  _and: [GsocNodeDataplaneDetailsFilterInput!]
  _exists: Boolean
  _not: GsocNodeDataplaneDetailsFilterInput
  _or: [GsocNodeDataplaneDetailsFilterInput!]
  dataDefender: GsocNodeDataplaneDataDefenderDetailsFilterInput
  dataflowType: StringFilterInput
  laminarFlow: GsocNodeDataplaneLaminarFlowDetailsFilterInput
}

type GsocNodeDataplaneLaminarFlowDetails {
  agent: GsocNodeAgentDetails
  egressAddress: GsocRangedConnectionDetails
  ingressAddress: GsocRangedConnectionDetails
  laminarflowConfig: String
  vita49StreamId: Int
}

input GsocNodeDataplaneLaminarFlowDetailsFilterInput {
  _and: [GsocNodeDataplaneLaminarFlowDetailsFilterInput!]
  _exists: Boolean
  _not: GsocNodeDataplaneLaminarFlowDetailsFilterInput
  _or: [GsocNodeDataplaneLaminarFlowDetailsFilterInput!]
  agent: GsocNodeAgentDetailsFilterInput
  egressAddress: GsocRangedConnectionDetailsFilterInput
  ingressAddress: GsocRangedConnectionDetailsFilterInput
  laminarflowConfig: StringFilterInput
  vita49StreamId: IntegerFilterInput
}

type GsocNodeDetails {
  dataplane: GsocNodeDataplaneDetails
  orchestration: GsocNodeOrchestrationDetails
}

input GsocNodeDetailsFilterInput {
  _and: [GsocNodeDetailsFilterInput!]
  _exists: Boolean
  _not: GsocNodeDetailsFilterInput
  _or: [GsocNodeDetailsFilterInput!]
  dataplane: GsocNodeDataplaneDetailsFilterInput
  orchestration: GsocNodeOrchestrationDetailsFilterInput
}

input GsocNodeFilterInput {
  _and: [GsocNodeFilterInput!]
  _exists: Boolean
  _not: GsocNodeFilterInput
  _or: [GsocNodeFilterInput!]
  capability: GsocCapabilityFilterInput
  config: GsocConfigFilterInput
  nodeDetails: GsocNodeDetailsFilterInput
  region: StringFilterInput
}

type GsocNodeOrchestrationDetails {
  antennaDemodDecodeDetails: GsocAntennaDemodDecodeDetails
  antennaDownlinkDetails: GsocAntennaDownlinkDetails
  connectionProxyDetails: GsocConnectionProxyDetails
  managedAntennaDemodDecodeDetails: GsocManagedAntennaDemodDecodeDetails
  s3PlaybackDetails: GsocS3PlaybackDetails
  s3RecordingDetails: GsocS3RecordingDetails
  uplinkDetails: GsocUplinkDetails
  uplinkEchoDetails: GsocUplinkEchoDetails
}

input GsocNodeOrchestrationDetailsFilterInput {
  _and: [GsocNodeOrchestrationDetailsFilterInput!]
  _exists: Boolean
  _not: GsocNodeOrchestrationDetailsFilterInput
  _or: [GsocNodeOrchestrationDetailsFilterInput!]
  antennaDemodDecodeDetails: GsocAntennaDemodDecodeDetailsFilterInput
  antennaDownlinkDetails: GsocAntennaDownlinkDetailsFilterInput
  connectionProxyDetails: GsocConnectionProxyDetailsFilterInput
  managedAntennaDemodDecodeDetails: GsocManagedAntennaDemodDecodeDetailsFilterInput
  s3PlaybackDetails: GsocS3PlaybackDetailsFilterInput
  s3RecordingDetails: GsocS3RecordingDetailsFilterInput
  uplinkDetails: GsocUplinkDetailsFilterInput
  uplinkEchoDetails: GsocUplinkEchoDetailsFilterInput
}

type GsocPageInfo {
  hasNextPage: Boolean!
  lastCursor: [String!]
}

type GsocRangedConnectionDetails {
  name: String
  portRange: GsocIntegerRange!
}

input GsocRangedConnectionDetailsFilterInput {
  _and: [GsocRangedConnectionDetailsFilterInput!]
  _exists: Boolean
  _not: GsocRangedConnectionDetailsFilterInput
  _or: [GsocRangedConnectionDetailsFilterInput!]
  name: StringFilterInput
  portRange: GsocIntegerRangeFilterInput
}

type GsocRfCharacteristics {
  bandwidth: Measurement
  centerFrequency: Measurement!
  polarization: Polarization!
}

input GsocRfCharacteristicsFilterInput {
  _and: [GsocRfCharacteristicsFilterInput!]
  _exists: Boolean
  _not: GsocRfCharacteristicsFilterInput
  _or: [GsocRfCharacteristicsFilterInput!]
  bandwidth: MeasurementFilterInput
  centerFrequency: MeasurementFilterInput
  polarization: StringFilterInput
}

type GsocS3PlaybackDetails {
  bucketArn: String
  bucketRegion: String!
  keys: [String!]
  roleArn: String
}

input GsocS3PlaybackDetailsFilterInput {
  _and: [GsocS3PlaybackDetailsFilterInput!]
  _exists: Boolean
  _not: GsocS3PlaybackDetailsFilterInput
  _or: [GsocS3PlaybackDetailsFilterInput!]
  bucketArn: StringFilterInput
  bucketRegion: StringFilterInput
  keys: StringFilterInput
  roleArn: StringFilterInput
}

type GsocS3RecordingDetails {
  bucketArn: String
  bucketRegion: String!
  keyTemplate: String
  roleArn: String
}

input GsocS3RecordingDetailsFilterInput {
  _and: [GsocS3RecordingDetailsFilterInput!]
  _exists: Boolean
  _not: GsocS3RecordingDetailsFilterInput
  _or: [GsocS3RecordingDetailsFilterInput!]
  bucketArn: StringFilterInput
  bucketRegion: StringFilterInput
  keyTemplate: StringFilterInput
  roleArn: StringFilterInput
}

type GsocSatellite {
  customer: GsocCustomer!
  satelliteArn: String
  satelliteCatalogNumber: Int
  satelliteName: String
  tags: [String!]
}

type GsocSatelliteAggregation {
  satelliteCatalogNumber: Aggregation!
}

input GsocSatelliteFilterInput {
  _and: [GsocSatelliteFilterInput!]
  _exists: Boolean
  _not: GsocSatelliteFilterInput
  _or: [GsocSatelliteFilterInput!]
  customer: GsocCustomerFilterInput
  satelliteArn: GsocStringFilterInput
  satelliteCatalogNumber: IntegerFilterInput
  satelliteName: GsocStringFilterInput
  tags: GsocStringFilterInput
}

input GsocSatelliteSortInput {
  satelliteArn: SortOrder
  satelliteCatalogNumber: SortOrder
  satelliteName: SortOrder
}

type GsocSecurityDetails {
  roleArn: String
  securityGroupIds: [String!]!
  subnetIds: [String!]!
}

input GsocSecurityDetailsFilterInput {
  _and: [GsocSecurityDetailsFilterInput!]
  _exists: Boolean
  _not: GsocSecurityDetailsFilterInput
  _or: [GsocSecurityDetailsFilterInput!]
  roleArn: StringFilterInput
  securityGroupIds: StringFilterInput
  subnetIds: StringFilterInput
}

input GsocStringFilterInput {
  _eq: String
  _exists: Boolean
  _gt: String
  _gte: String
  _in: [String!]
  _lt: String
  _lte: String
  _neq: String
  _nin: [String!]
  _startsWith: String
}

input GsocTimeRangeFilterInput {
  _exists: Boolean
  endTime: AWSDateTimeFilterInput
  startTime: AWSDateTimeFilterInput
}

type GsocUplinkDetails {
  rfCharacteristics: GsocRfCharacteristics!
  targetEirp: Measurement!
  transmitDisabled: Boolean!
}

input GsocUplinkDetailsFilterInput {
  _and: [GsocUplinkDetailsFilterInput!]
  _exists: Boolean
  _not: GsocUplinkDetailsFilterInput
  _or: [GsocUplinkDetailsFilterInput!]
  rfCharacteristics: GsocRfCharacteristicsFilterInput
  targetEirp: MeasurementFilterInput
  transmitDisabled: BooleanFilterInput
}

type GsocUplinkEchoDetails {
  enabled: Boolean!
  rfCharacteristics: GsocRfCharacteristics!
}

input GsocUplinkEchoDetailsFilterInput {
  _and: [GsocUplinkEchoDetailsFilterInput!]
  _exists: Boolean
  _not: GsocUplinkEchoDetailsFilterInput
  _or: [GsocUplinkEchoDetailsFilterInput!]
  enabled: BooleanFilterInput
  rfCharacteristics: GsocRfCharacteristicsFilterInput
}

type InRegionAntennaDownlinkConfigData {
  bandwidth: Frequency
  centerFrequency: Frequency
  polarity: Polarization
}

type InRegionAntennaDownlinkDemodDecodeConfigData {
  bandwidthValue: Frequency
  centerFrequency: Frequency
  decodeConfigData: InRegionDecodeConfigData
  demodulationConfigData: InRegionDemodulationConfigData
  polarity: Polarization
}

type InRegionAntennaDownlinkDemodDecodeExecutionContextData {
  antennaDownlinkDemodDecodeConfigData: InRegionAntennaDownlinkDemodDecodeConfigData
  antennaDownlinkDemodDecodeConfigId: String
  configArn: String
  dataflowType: CpDataflowType
  executionContextDataflows: [InRegionExecutionContextDataflowData!]
  outputToDataDeliveryServiceSourceArnMap: AWSJSON
  outputToDestinationMtuMap: AWSJSON
}

type InRegionAntennaDownlinkExecutionContextData {
  antennaDownlinkConfigData: InRegionAntennaDownlinkConfigData
  antennaDownlinkConfigId: String
  configArn: String
  dataDeliveryServiceSourceArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
}

type InRegionAntennaUplinkConfigData {
  centerFrequency: Frequency
  polarity: Polarization
  targetEirpUnit: String
  targetEirpValue: Float
  transmitDisabled: Boolean
}

type InRegionAntennaUplinkExecutionContextData {
  antennaUplinkConfigData: InRegionAntennaUplinkConfigData
  antennaUplinkConfigId: String
  configArn: String
  dataDeliveryServiceSourceArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
}

type InRegionConnectionProxyExecutionContextData {
  configArn: String
  dataflowTranslation: DataflowTranslation
  executionContextDataflowData: InRegionExecutionContextDataflowData
}

enum InRegionContactDataType {
  CONTACT_REQUEST
  EXECUTION_CONTEXT
}

type InRegionContactDdbTable {
  contactId: String!
  contactRequestData: InRegionContactRequestData
  dataUuid: String!
  executionContext: String
  executionContextStruct: InRegionExecutionContextData
  inRegionContactDataType: InRegionContactDataType!
  versionNumber: Int!
}

type InRegionContactRequestData {
  antennaSystemId: String
  awsAccountId: String
  capabilitiesToTest: [String!]
  contactArn: String
  contactId: String
  contactType: ContactType
  createdInRegion: String
  dereferenceMissionProfileData: DereferencedMissionProfileData
  groundstationId: String
  requestedNodes: AWSJSON
  satelliteArn: String
  targetRegion: String
}

type InRegionDataflowEndpointExecutionContextData {
  configArn: String
  dataDeliveryServiceDestinationArn: String
  dataDeliveryServiceSourceArn: String
  dataflowEndpointGroupArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
}

type InRegionDecodeConfigData {
  unvalidatedJSON: String
}

type InRegionDemodulationConfigData {
  unvalidatedJSON: String
}

type InRegionExecutionContextData {
  antennaDownlinkDemodDecodeExecutionContextData: InRegionAntennaDownlinkDemodDecodeExecutionContextData
  antennaDownlinkExecutionContextData: InRegionAntennaDownlinkExecutionContextData
  antennaUplinkExecutionContextData: InRegionAntennaUplinkExecutionContextData
  capabilityArn: String
  configArn: String
  connectionProxyExecutionContextData: InRegionConnectionProxyExecutionContextData
  dataflowEndpointExecutionContextData: InRegionDataflowEndpointExecutionContextData
  dataflowType: CpDataflowType
  executionContextId: String
  executionContextType: ConfigCapabilityType
  laminarFlowConfigString: String
  managedAntennaDownlinkDemodDecodeExecutionContextData: InRegionManagedAntennaDownlinkDemodDecodeExecutionContextData
  s3PlaybackExecutionContextData: InRegionS3PlaybackExecutionContextData
  s3RecordingExecutionContextData: InRegionS3RecordingExecutionContextData
  trackingExecutionContextData: InRegionTrackingExecutionContextData
  type: ConfigCapabilityType
  uplinkEchoExecutionContextData: InRegionUplinkEchoExecutionContextData
}

type InRegionExecutionContextDataflowData {
  dataflowId: String
  dataflowRate: Measurement
  destinationIngressAddress: InRegionRangedSocketAddressInfo
  egressAddress: InRegionRangedSocketAddressInfo
  ingressAddress: InRegionRangedSocketAddressInfo
  isMultiChannel: Boolean
  sourceAccount: String
  sourceArn: String
  sourceEgressAddress: InRegionRangedSocketAddressInfo
  vita49StreamId: Int
}

type InRegionManagedAntennaDownlinkDemodDecodeConfigData {
  managedRadioConfigId: String
  spectrumConfig: InRegionManagedAntennaDownlinkDemodDecodeSpectrumConfig
  supportedOutputTypes: [InRegionManagedAntennaDownlinkDemodDecodeSupportedOutputType!]
}

type InRegionManagedAntennaDownlinkDemodDecodeExecutionContextData {
  configArn: String
  dataflowType: CpDataflowType
  executionContextDataflows: [InRegionExecutionContextDataflowData!]
  managedAntennaDownlinkDemodDecodeConfigData: InRegionManagedAntennaDownlinkDemodDecodeConfigData
  managedAntennaDownlinkDemodDecodeConfigId: String
}

type InRegionManagedAntennaDownlinkDemodDecodeSpectrumConfig {
  bandwidth: Frequency
  centerFrequency: Frequency
  polarization: Polarization
}

type InRegionManagedAntennaDownlinkDemodDecodeSupportedOutputType {
  description: String
  name: String
}

type InRegionRangedSocketAddressInfo {
  maximumPort: Int
  minimumPort: Int
  name: String
}

type InRegionS3PlaybackConfigData {
  bucketArn: String
  bucketRegion: String
  playbackFrequency: Frequency
  roleArn: String
}

type InRegionS3PlaybackConfigDto {
  awsAccountId: String
  configArn: String
  configId: String
  s3PlaybackConfigData: InRegionS3PlaybackConfigData
  type: ConfigCapabilityType
}

type InRegionS3PlaybackExecutionContextData {
  configArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
  s3PlaybackConfigDto: InRegionS3PlaybackConfigDto
}

type InRegionS3RecordingConfigData {
  bucketArn: String
  bucketRegion: String
  prefix: String
  roleArn: String
}

type InRegionS3RecordingConfigDto {
  awsAccountId: String
  configArn: String
  configId: String
  createdInRegion: String
  name: String
  region: String
  s3RecordingConfigData: InRegionS3RecordingConfigData
  type: ConfigCapabilityType
}

type InRegionS3RecordingExecutionContextData {
  dataDeliveryServiceDestinationArn: String
  dataDeliveryServiceSourceArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
  s3RecordingConfigDto: InRegionS3RecordingConfigDto
}

type InRegionTrackingExecutionContextData {
  autotrackCriticality: CpCriticality
}

type InRegionUplinkEchoConfigData {
  antennaUplinkArn: String
  centerFrequency: Frequency
  enabled: Boolean
  polarity: Polarization
}

type InRegionUplinkEchoExecutionContextData {
  configArn: String
  dataDeliveryServiceSourceArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
  uplinkEchoConfigData: InRegionUplinkEchoConfigData
  uplinkEchoConfigId: String
}

input IntegerFilterInput {
  _eq: Int
  _exists: Boolean
  _gt: Int
  _gte: Int
  _in: [Int!]
  _lt: Int
  _lte: Int
  _neq: Int
  _nin: [Int!]
}

type LaminarFlowMetric {
  avg: Float
  max: Float
  median: Float
  min: Float
  p50: Float
  p75: Float
  p90: Float
  p95: Float
  p99: Float
  stdDev: Float
  total: Float
}

type LaminarFlowMetricAggregation {
  avg: Aggregation!
  max: Aggregation!
  median: Aggregation!
  min: Aggregation!
  p50: Aggregation!
  p75: Aggregation!
  p90: Aggregation!
  p95: Aggregation!
  p99: Aggregation!
  stdDev: Aggregation!
  total: Aggregation!
}

input LaminarFlowMetricFilterInput {
  _and: [LaminarFlowMetricFilterInput!]
  _exists: Boolean
  _not: LaminarFlowMetricFilterInput
  _or: [LaminarFlowMetricFilterInput!]
  avg: FloatFilterInput
  max: FloatFilterInput
  median: FloatFilterInput
  min: FloatFilterInput
  p50: FloatFilterInput
  p75: FloatFilterInput
  p90: FloatFilterInput
  p95: FloatFilterInput
  p99: FloatFilterInput
  stdDev: FloatFilterInput
  total: FloatFilterInput
}

input LaminarFlowMetricSortInput {
  avg: SortOrder
  max: SortOrder
  median: SortOrder
  min: SortOrder
  p50: SortOrder
  p75: SortOrder
  p90: SortOrder
  p95: SortOrder
  p99: SortOrder
  stdDev: SortOrder
  total: SortOrder
}

type LfDecryptStageMetricsV2 {
  id: String
  metric__common_stage_metrics__tick_rate: LaminarFlowMetric
  metric__decryption_error_rate: LaminarFlowMetric
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetric
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetric
  metric__message_metrics__input_message_rate: LaminarFlowMetric
  metric__message_metrics__output_message_rate: LaminarFlowMetric
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetric
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetric
  metric__total_decryption_errors: LaminarFlowMetric
}

type LfDecryptStageMetricsV2Aggregation {
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricAggregation!
  metric__decryption_error_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricAggregation!
  metric__message_metrics__input_message_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__output_message_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricAggregation!
  metric__total_decryption_errors: LaminarFlowMetricAggregation!
}

input LfDecryptStageMetricsV2FilterInput {
  _and: [LfDecryptStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfDecryptStageMetricsV2FilterInput
  _or: [LfDecryptStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricFilterInput
  metric__decryption_error_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricFilterInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricFilterInput
  metric__total_decryption_errors: LaminarFlowMetricFilterInput
}

input LfDecryptStageMetricsV2SortInput {
  id: SortOrder
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricSortInput
  metric__decryption_error_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricSortInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricSortInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricSortInput
  metric__total_decryption_errors: LaminarFlowMetricSortInput
}

type LfEgressStageMetricsV2 {
  id: String
  metric__average_bytes_per_syscall: LaminarFlowMetric
  metric__average_datagrams_per_syscall: LaminarFlowMetric
  metric__average_max_depth_per_syscall: LaminarFlowMetric
  metric__average_min_depth_per_syscall: LaminarFlowMetric
  metric__average_width_per_syscall: LaminarFlowMetric
  metric__common_stage_metrics__tick_rate: LaminarFlowMetric
  metric__connection_error_rate: LaminarFlowMetric
  metric__connection_refused_error_rate: LaminarFlowMetric
  metric__initial_input_sequence_count: LaminarFlowMetric
  metric__input_bit_rate: LaminarFlowMetric
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetric
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetric
  metric__last_input_sequence_count: LaminarFlowMetric
  metric__message_metrics__input_message_rate: LaminarFlowMetric
  metric__message_metrics__output_message_rate: LaminarFlowMetric
  metric__network_unreachable_error_rate: LaminarFlowMetric
  metric__output_bit_rate: LaminarFlowMetric
  metric__overall_timestamp_delta_sec_max: LaminarFlowMetric
  metric__overall_timestamp_delta_sec_min: LaminarFlowMetric
  metric__packets_lost_sequence_count: LaminarFlowMetric
  metric__packets_out_of_order_sequence_count: LaminarFlowMetric
  metric__socket_overflow_rate: LaminarFlowMetric
  metric__timestamp_delta_sec_max: LaminarFlowMetric
  metric__timestamp_delta_sec_min: LaminarFlowMetric
  metric__total_input_bytes: LaminarFlowMetric
  metric__total_output_bytes: LaminarFlowMetric
  metric__total_packets_lost_sequence_count: LaminarFlowMetric
  metric__total_packets_out_of_order_sequence_count: LaminarFlowMetric
  metric__total_retransmitted_messages: LaminarFlowMetric
  metric__total_socket_overflows: LaminarFlowMetric
}

type LfEgressStageMetricsV2Aggregation {
  metric__average_bytes_per_syscall: LaminarFlowMetricAggregation!
  metric__average_datagrams_per_syscall: LaminarFlowMetricAggregation!
  metric__average_max_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__average_min_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__average_width_per_syscall: LaminarFlowMetricAggregation!
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricAggregation!
  metric__connection_error_rate: LaminarFlowMetricAggregation!
  metric__connection_refused_error_rate: LaminarFlowMetricAggregation!
  metric__initial_input_sequence_count: LaminarFlowMetricAggregation!
  metric__input_bit_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricAggregation!
  metric__last_input_sequence_count: LaminarFlowMetricAggregation!
  metric__message_metrics__input_message_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__output_message_rate: LaminarFlowMetricAggregation!
  metric__network_unreachable_error_rate: LaminarFlowMetricAggregation!
  metric__output_bit_rate: LaminarFlowMetricAggregation!
  metric__overall_timestamp_delta_sec_max: LaminarFlowMetricAggregation!
  metric__overall_timestamp_delta_sec_min: LaminarFlowMetricAggregation!
  metric__packets_lost_sequence_count: LaminarFlowMetricAggregation!
  metric__packets_out_of_order_sequence_count: LaminarFlowMetricAggregation!
  metric__socket_overflow_rate: LaminarFlowMetricAggregation!
  metric__timestamp_delta_sec_max: LaminarFlowMetricAggregation!
  metric__timestamp_delta_sec_min: LaminarFlowMetricAggregation!
  metric__total_input_bytes: LaminarFlowMetricAggregation!
  metric__total_output_bytes: LaminarFlowMetricAggregation!
  metric__total_packets_lost_sequence_count: LaminarFlowMetricAggregation!
  metric__total_packets_out_of_order_sequence_count: LaminarFlowMetricAggregation!
  metric__total_retransmitted_messages: LaminarFlowMetricAggregation!
  metric__total_socket_overflows: LaminarFlowMetricAggregation!
}

input LfEgressStageMetricsV2FilterInput {
  _and: [LfEgressStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfEgressStageMetricsV2FilterInput
  _or: [LfEgressStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__average_bytes_per_syscall: LaminarFlowMetricFilterInput
  metric__average_datagrams_per_syscall: LaminarFlowMetricFilterInput
  metric__average_max_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__average_min_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__average_width_per_syscall: LaminarFlowMetricFilterInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricFilterInput
  metric__connection_error_rate: LaminarFlowMetricFilterInput
  metric__connection_refused_error_rate: LaminarFlowMetricFilterInput
  metric__initial_input_sequence_count: LaminarFlowMetricFilterInput
  metric__input_bit_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricFilterInput
  metric__last_input_sequence_count: LaminarFlowMetricFilterInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricFilterInput
  metric__network_unreachable_error_rate: LaminarFlowMetricFilterInput
  metric__output_bit_rate: LaminarFlowMetricFilterInput
  metric__overall_timestamp_delta_sec_max: LaminarFlowMetricFilterInput
  metric__overall_timestamp_delta_sec_min: LaminarFlowMetricFilterInput
  metric__packets_lost_sequence_count: LaminarFlowMetricFilterInput
  metric__packets_out_of_order_sequence_count: LaminarFlowMetricFilterInput
  metric__socket_overflow_rate: LaminarFlowMetricFilterInput
  metric__timestamp_delta_sec_max: LaminarFlowMetricFilterInput
  metric__timestamp_delta_sec_min: LaminarFlowMetricFilterInput
  metric__total_input_bytes: LaminarFlowMetricFilterInput
  metric__total_output_bytes: LaminarFlowMetricFilterInput
  metric__total_packets_lost_sequence_count: LaminarFlowMetricFilterInput
  metric__total_packets_out_of_order_sequence_count: LaminarFlowMetricFilterInput
  metric__total_retransmitted_messages: LaminarFlowMetricFilterInput
  metric__total_socket_overflows: LaminarFlowMetricFilterInput
}

input LfEgressStageMetricsV2SortInput {
  id: SortOrder
  metric__average_bytes_per_syscall: LaminarFlowMetricSortInput
  metric__average_datagrams_per_syscall: LaminarFlowMetricSortInput
  metric__average_max_depth_per_syscall: LaminarFlowMetricSortInput
  metric__average_min_depth_per_syscall: LaminarFlowMetricSortInput
  metric__average_width_per_syscall: LaminarFlowMetricSortInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricSortInput
  metric__connection_error_rate: LaminarFlowMetricSortInput
  metric__connection_refused_error_rate: LaminarFlowMetricSortInput
  metric__initial_input_sequence_count: LaminarFlowMetricSortInput
  metric__input_bit_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricSortInput
  metric__last_input_sequence_count: LaminarFlowMetricSortInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricSortInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricSortInput
  metric__network_unreachable_error_rate: LaminarFlowMetricSortInput
  metric__output_bit_rate: LaminarFlowMetricSortInput
  metric__overall_timestamp_delta_sec_max: LaminarFlowMetricSortInput
  metric__overall_timestamp_delta_sec_min: LaminarFlowMetricSortInput
  metric__packets_lost_sequence_count: LaminarFlowMetricSortInput
  metric__packets_out_of_order_sequence_count: LaminarFlowMetricSortInput
  metric__socket_overflow_rate: LaminarFlowMetricSortInput
  metric__timestamp_delta_sec_max: LaminarFlowMetricSortInput
  metric__timestamp_delta_sec_min: LaminarFlowMetricSortInput
  metric__total_input_bytes: LaminarFlowMetricSortInput
  metric__total_output_bytes: LaminarFlowMetricSortInput
  metric__total_packets_lost_sequence_count: LaminarFlowMetricSortInput
  metric__total_packets_out_of_order_sequence_count: LaminarFlowMetricSortInput
  metric__total_retransmitted_messages: LaminarFlowMetricSortInput
  metric__total_socket_overflows: LaminarFlowMetricSortInput
}

type LfEncryptStageMetricsV2 {
  id: String
  metric__common_stage_metrics__tick_rate: LaminarFlowMetric
  metric__encryption_error_rate: LaminarFlowMetric
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetric
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetric
  metric__message_metrics__input_message_rate: LaminarFlowMetric
  metric__message_metrics__output_message_rate: LaminarFlowMetric
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetric
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetric
  metric__total_encryption_errors: LaminarFlowMetric
}

type LfEncryptStageMetricsV2Aggregation {
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricAggregation!
  metric__encryption_error_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricAggregation!
  metric__message_metrics__input_message_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__output_message_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricAggregation!
  metric__total_encryption_errors: LaminarFlowMetricAggregation!
}

input LfEncryptStageMetricsV2FilterInput {
  _and: [LfEncryptStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfEncryptStageMetricsV2FilterInput
  _or: [LfEncryptStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricFilterInput
  metric__encryption_error_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricFilterInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricFilterInput
  metric__total_encryption_errors: LaminarFlowMetricFilterInput
}

input LfEncryptStageMetricsV2SortInput {
  id: SortOrder
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricSortInput
  metric__encryption_error_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricSortInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricSortInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricSortInput
  metric__total_encryption_errors: LaminarFlowMetricSortInput
}

type LfIngressStageMetricsV2 {
  id: String
  metric__common_stage_metrics__tick_rate: LaminarFlowMetric
  metric__input_bit_rate: LaminarFlowMetric
  metric__input_drop_rate: LaminarFlowMetric
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetric
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetric
  metric__last_output_sequence_count: LaminarFlowMetric
  metric__message_metrics__input_message_rate: LaminarFlowMetric
  metric__message_metrics__output_message_rate: LaminarFlowMetric
  metric__output_bit_rate: LaminarFlowMetric
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetric
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetric
  metric__socket_back_off_rate: LaminarFlowMetric
  metric__socket_error_rate: LaminarFlowMetric
  metric__total_early_input_bytes: LaminarFlowMetric
  metric__total_input_bytes: LaminarFlowMetric
  metric__total_input_drops: LaminarFlowMetric
  metric__total_late_input_bytes: LaminarFlowMetric
  metric__total_output_bytes: LaminarFlowMetric
  metric__total_socket_back_offs: LaminarFlowMetric
  metric__total_socket_errors: LaminarFlowMetric
  metric__total_timely_input_bytes: LaminarFlowMetric
}

type LfIngressStageMetricsV2Aggregation {
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricAggregation!
  metric__input_bit_rate: LaminarFlowMetricAggregation!
  metric__input_drop_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricAggregation!
  metric__last_output_sequence_count: LaminarFlowMetricAggregation!
  metric__message_metrics__input_message_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__output_message_rate: LaminarFlowMetricAggregation!
  metric__output_bit_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricAggregation!
  metric__socket_back_off_rate: LaminarFlowMetricAggregation!
  metric__socket_error_rate: LaminarFlowMetricAggregation!
  metric__total_early_input_bytes: LaminarFlowMetricAggregation!
  metric__total_input_bytes: LaminarFlowMetricAggregation!
  metric__total_input_drops: LaminarFlowMetricAggregation!
  metric__total_late_input_bytes: LaminarFlowMetricAggregation!
  metric__total_output_bytes: LaminarFlowMetricAggregation!
  metric__total_socket_back_offs: LaminarFlowMetricAggregation!
  metric__total_socket_errors: LaminarFlowMetricAggregation!
  metric__total_timely_input_bytes: LaminarFlowMetricAggregation!
}

input LfIngressStageMetricsV2FilterInput {
  _and: [LfIngressStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfIngressStageMetricsV2FilterInput
  _or: [LfIngressStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricFilterInput
  metric__input_bit_rate: LaminarFlowMetricFilterInput
  metric__input_drop_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricFilterInput
  metric__last_output_sequence_count: LaminarFlowMetricFilterInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricFilterInput
  metric__output_bit_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricFilterInput
  metric__socket_back_off_rate: LaminarFlowMetricFilterInput
  metric__socket_error_rate: LaminarFlowMetricFilterInput
  metric__total_early_input_bytes: LaminarFlowMetricFilterInput
  metric__total_input_bytes: LaminarFlowMetricFilterInput
  metric__total_input_drops: LaminarFlowMetricFilterInput
  metric__total_late_input_bytes: LaminarFlowMetricFilterInput
  metric__total_output_bytes: LaminarFlowMetricFilterInput
  metric__total_socket_back_offs: LaminarFlowMetricFilterInput
  metric__total_socket_errors: LaminarFlowMetricFilterInput
  metric__total_timely_input_bytes: LaminarFlowMetricFilterInput
}

input LfIngressStageMetricsV2SortInput {
  id: SortOrder
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricSortInput
  metric__input_bit_rate: LaminarFlowMetricSortInput
  metric__input_drop_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricSortInput
  metric__last_output_sequence_count: LaminarFlowMetricSortInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricSortInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricSortInput
  metric__output_bit_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricSortInput
  metric__socket_back_off_rate: LaminarFlowMetricSortInput
  metric__socket_error_rate: LaminarFlowMetricSortInput
  metric__total_early_input_bytes: LaminarFlowMetricSortInput
  metric__total_input_bytes: LaminarFlowMetricSortInput
  metric__total_input_drops: LaminarFlowMetricSortInput
  metric__total_late_input_bytes: LaminarFlowMetricSortInput
  metric__total_output_bytes: LaminarFlowMetricSortInput
  metric__total_socket_back_offs: LaminarFlowMetricSortInput
  metric__total_socket_errors: LaminarFlowMetricSortInput
  metric__total_timely_input_bytes: LaminarFlowMetricSortInput
}

type LfStripeDecodeStageMetricsV2 {
  id: String
  metric__common_stage_metrics__tick_rate: LaminarFlowMetric
  metric__decoding_input_metrics__input_block_error_rate: LaminarFlowMetric
  metric__decoding_input_metrics__input_block_rate: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_duplicated: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_missing: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_missing_history: [Float!]
  metric__decoding_input_metrics__input_blocks_orphaned: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_out_of_order: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_recovered: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_stale: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_unrecoverable: LaminarFlowMetric
  metric__decoding_input_metrics__input_virtual_fill_rate: LaminarFlowMetric
  metric__head_block_set_number: LaminarFlowMetric
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetric
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetric
  metric__last_processed_stripe_index: LaminarFlowMetric
  metric__output_block_error_rate: LaminarFlowMetric
  metric__output_message_rate: LaminarFlowMetric
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetric
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetric
  metric__tail_block_set_number: LaminarFlowMetric
}

type LfStripeDecodeStageMetricsV2Aggregation {
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_block_error_rate: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_block_rate: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_duplicated: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_missing: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_orphaned: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_out_of_order: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_recovered: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_stale: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_unrecoverable: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_virtual_fill_rate: LaminarFlowMetricAggregation!
  metric__head_block_set_number: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricAggregation!
  metric__last_processed_stripe_index: LaminarFlowMetricAggregation!
  metric__output_block_error_rate: LaminarFlowMetricAggregation!
  metric__output_message_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricAggregation!
  metric__tail_block_set_number: LaminarFlowMetricAggregation!
}

input LfStripeDecodeStageMetricsV2FilterInput {
  _and: [LfStripeDecodeStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfStripeDecodeStageMetricsV2FilterInput
  _or: [LfStripeDecodeStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_block_error_rate: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_block_rate: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_duplicated: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_missing: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_missing_history: FloatFilterInput
  metric__decoding_input_metrics__input_blocks_orphaned: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_out_of_order: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_recovered: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_stale: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_unrecoverable: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_virtual_fill_rate: LaminarFlowMetricFilterInput
  metric__head_block_set_number: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricFilterInput
  metric__last_processed_stripe_index: LaminarFlowMetricFilterInput
  metric__output_block_error_rate: LaminarFlowMetricFilterInput
  metric__output_message_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricFilterInput
  metric__tail_block_set_number: LaminarFlowMetricFilterInput
}

input LfStripeDecodeStageMetricsV2SortInput {
  id: SortOrder
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_block_error_rate: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_block_rate: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_duplicated: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_missing: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_orphaned: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_out_of_order: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_recovered: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_stale: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_unrecoverable: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_virtual_fill_rate: LaminarFlowMetricSortInput
  metric__head_block_set_number: LaminarFlowMetricSortInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricSortInput
  metric__last_processed_stripe_index: LaminarFlowMetricSortInput
  metric__output_block_error_rate: LaminarFlowMetricSortInput
  metric__output_message_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricSortInput
  metric__tail_block_set_number: LaminarFlowMetricSortInput
}

type LfStripeEncodeStageMetricsV2 {
  id: String
  metric__common_stage_metrics__tick_rate: LaminarFlowMetric
  metric__message_metrics__input_message_rate: LaminarFlowMetric
  metric__message_metrics__output_message_rate: LaminarFlowMetric
}

type LfStripeEncodeStageMetricsV2Aggregation {
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__input_message_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__output_message_rate: LaminarFlowMetricAggregation!
}

input LfStripeEncodeStageMetricsV2FilterInput {
  _and: [LfStripeEncodeStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfStripeEncodeStageMetricsV2FilterInput
  _or: [LfStripeEncodeStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricFilterInput
}

input LfStripeEncodeStageMetricsV2SortInput {
  id: SortOrder
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricSortInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricSortInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricSortInput
}

type LfTimeDecodeStageMetricsV2 {
  id: String
  metric__common_stage_metrics__tick_rate: LaminarFlowMetric
  metric__decoding_input_metrics__input_block_error_rate: LaminarFlowMetric
  metric__decoding_input_metrics__input_block_rate: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_duplicated: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_missing: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_missing_history: [Float!]
  metric__decoding_input_metrics__input_blocks_orphaned: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_out_of_order: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_recovered: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_stale: LaminarFlowMetric
  metric__decoding_input_metrics__input_blocks_unrecoverable: LaminarFlowMetric
  metric__decoding_input_metrics__input_virtual_fill_rate: LaminarFlowMetric
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetric
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetric
  metric__output_block_error_rate: LaminarFlowMetric
  metric__output_message_rate: LaminarFlowMetric
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetric
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetric
}

type LfTimeDecodeStageMetricsV2Aggregation {
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_block_error_rate: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_block_rate: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_duplicated: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_missing: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_orphaned: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_out_of_order: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_recovered: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_stale: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_blocks_unrecoverable: LaminarFlowMetricAggregation!
  metric__decoding_input_metrics__input_virtual_fill_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricAggregation!
  metric__output_block_error_rate: LaminarFlowMetricAggregation!
  metric__output_message_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricAggregation!
}

input LfTimeDecodeStageMetricsV2FilterInput {
  _and: [LfTimeDecodeStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfTimeDecodeStageMetricsV2FilterInput
  _or: [LfTimeDecodeStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_block_error_rate: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_block_rate: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_duplicated: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_missing: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_missing_history: FloatFilterInput
  metric__decoding_input_metrics__input_blocks_orphaned: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_out_of_order: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_recovered: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_stale: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_blocks_unrecoverable: LaminarFlowMetricFilterInput
  metric__decoding_input_metrics__input_virtual_fill_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricFilterInput
  metric__output_block_error_rate: LaminarFlowMetricFilterInput
  metric__output_message_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricFilterInput
}

input LfTimeDecodeStageMetricsV2SortInput {
  id: SortOrder
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_block_error_rate: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_block_rate: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_duplicated: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_missing: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_orphaned: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_out_of_order: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_recovered: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_stale: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_blocks_unrecoverable: LaminarFlowMetricSortInput
  metric__decoding_input_metrics__input_virtual_fill_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricSortInput
  metric__output_block_error_rate: LaminarFlowMetricSortInput
  metric__output_message_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricSortInput
}

type LfTimeEncodeStageMetricsV2 {
  id: String
  metric__common_stage_metrics__tick_rate: LaminarFlowMetric
  metric__message_metrics__input_message_rate: LaminarFlowMetric
  metric__message_metrics__output_message_rate: LaminarFlowMetric
}

type LfTimeEncodeStageMetricsV2Aggregation {
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__input_message_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__output_message_rate: LaminarFlowMetricAggregation!
}

input LfTimeEncodeStageMetricsV2FilterInput {
  _and: [LfTimeEncodeStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfTimeEncodeStageMetricsV2FilterInput
  _or: [LfTimeEncodeStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricFilterInput
}

input LfTimeEncodeStageMetricsV2SortInput {
  id: SortOrder
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricSortInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricSortInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricSortInput
}

type LfTracerouteStageMetricsV2 {
  id: String
  metric__dest: String
  metric__hop_count: Int
  metric__hops__addr: [String!]
  metric__hops__est_rtt: [Float!]
  metric__hops__ttl: [Float!]
  metric__id: String
  metric__source: String
  metric__status: String
  timestamp: String
}

input LfTracerouteStageMetricsV2FilterInput {
  _and: [LfTracerouteStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfTracerouteStageMetricsV2FilterInput
  _or: [LfTracerouteStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__dest: StringFilterInput
  metric__hop_count: IntegerFilterInput
  metric__hops__addr: StringFilterInput
  metric__hops__est_rtt: FloatFilterInput
  metric__hops__ttl: FloatFilterInput
  metric__id: StringFilterInput
  metric__source: StringFilterInput
  metric__status: StringFilterInput
  timestamp: StringFilterInput
}

type LfWanRxStageMetricsV2 {
  id: String
  metric__bytes_metrics__input_bit_rate: LaminarFlowMetric
  metric__bytes_metrics__output_bit_rate: LaminarFlowMetric
  metric__bytes_metrics__total_input_bytes: LaminarFlowMetric
  metric__bytes_metrics__total_output_bytes: LaminarFlowMetric
  metric__common_stage_metrics__tick_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__selected_lessor: String
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__received_heartbeat_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__selected_lessee: String
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__transmitted_heartbeat_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__transmitted_lease_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__decryption_error_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__decryption_success_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__input_control_message_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__output_control_message_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__unsupported_input_control_message_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__unsupported_output_control_message_rate: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetric
  metric__message_metrics__input_message_rate: LaminarFlowMetric
  metric__message_metrics__output_message_rate: LaminarFlowMetric
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetric
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetric
}

type LfWanRxStageMetricsV2Aggregation {
  metric__bytes_metrics__input_bit_rate: LaminarFlowMetricAggregation!
  metric__bytes_metrics__output_bit_rate: LaminarFlowMetricAggregation!
  metric__bytes_metrics__total_input_bytes: LaminarFlowMetricAggregation!
  metric__bytes_metrics__total_output_bytes: LaminarFlowMetricAggregation!
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__received_heartbeat_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__transmitted_heartbeat_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__transmitted_lease_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__decryption_error_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__decryption_success_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__input_control_message_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__output_control_message_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__unsupported_input_control_message_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__unsupported_output_control_message_rate: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__input_message_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__output_message_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricAggregation!
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricAggregation!
}

input LfWanRxStageMetricsV2FilterInput {
  _and: [LfWanRxStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfWanRxStageMetricsV2FilterInput
  _or: [LfWanRxStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__bytes_metrics__input_bit_rate: LaminarFlowMetricFilterInput
  metric__bytes_metrics__output_bit_rate: LaminarFlowMetricFilterInput
  metric__bytes_metrics__total_input_bytes: LaminarFlowMetricFilterInput
  metric__bytes_metrics__total_output_bytes: LaminarFlowMetricFilterInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__selected_lessor: StringFilterInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__received_heartbeat_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__selected_lessee: StringFilterInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__transmitted_heartbeat_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__transmitted_lease_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__decryption_error_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__decryption_success_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__input_control_message_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__output_control_message_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__unsupported_input_control_message_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__unsupported_output_control_message_rate: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricFilterInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricFilterInput
}

input LfWanRxStageMetricsV2SortInput {
  id: SortOrder
  metric__bytes_metrics__input_bit_rate: LaminarFlowMetricSortInput
  metric__bytes_metrics__output_bit_rate: LaminarFlowMetricSortInput
  metric__bytes_metrics__total_input_bytes: LaminarFlowMetricSortInput
  metric__bytes_metrics__total_output_bytes: LaminarFlowMetricSortInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__selected_lessor: SortOrder
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__received_heartbeat_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__selected_lessee: SortOrder
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__transmitted_heartbeat_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__transmitted_lease_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__decryption_error_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__decryption_success_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__input_control_message_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__output_control_message_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__unsupported_input_control_message_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__unsupported_output_control_message_rate: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricSortInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricSortInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__output_queue_overflow_rate: LaminarFlowMetricSortInput
  metric__output_queue_metrics__total_output_queue_overflows: LaminarFlowMetricSortInput
}

type LfWanTxStageMetricsV2 {
  id: String
  metric__bytes_metrics__input_bit_rate: LaminarFlowMetric
  metric__bytes_metrics__output_bit_rate: LaminarFlowMetric
  metric__bytes_metrics__total_input_bytes: LaminarFlowMetric
  metric__bytes_metrics__total_output_bytes: LaminarFlowMetric
  metric__common_stage_metrics__tick_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__received_heartbeat_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__received_lease_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__selected_lessor: String
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__transmitted_heartbeat_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__selected_lessee: String
  metric__control_message_manager_metrics__control_message_metrics__decryption_error_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__decryption_success_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__input_control_message_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__output_control_message_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__unsupported_input_control_message_rate: LaminarFlowMetric
  metric__control_message_manager_metrics__control_message_metrics__unsupported_output_control_message_rate: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetric
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetric
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetric
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetric
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetric
  metric__dropped_message_count: LaminarFlowMetric
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetric
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetric
  metric__message_metrics__input_message_rate: LaminarFlowMetric
  metric__message_metrics__output_message_rate: LaminarFlowMetric
}

type LfWanTxStageMetricsV2Aggregation {
  metric__bytes_metrics__input_bit_rate: LaminarFlowMetricAggregation!
  metric__bytes_metrics__output_bit_rate: LaminarFlowMetricAggregation!
  metric__bytes_metrics__total_input_bytes: LaminarFlowMetricAggregation!
  metric__bytes_metrics__total_output_bytes: LaminarFlowMetricAggregation!
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__received_heartbeat_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__received_lease_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__transmitted_heartbeat_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__decryption_error_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__decryption_success_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__input_control_message_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__output_control_message_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__unsupported_input_control_message_rate: LaminarFlowMetricAggregation!
  metric__control_message_manager_metrics__control_message_metrics__unsupported_output_control_message_rate: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricAggregation!
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricAggregation!
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricAggregation!
  metric__dropped_message_count: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricAggregation!
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricAggregation!
  metric__message_metrics__input_message_rate: LaminarFlowMetricAggregation!
  metric__message_metrics__output_message_rate: LaminarFlowMetricAggregation!
}

input LfWanTxStageMetricsV2FilterInput {
  _and: [LfWanTxStageMetricsV2FilterInput!]
  _exists: Boolean
  _not: LfWanTxStageMetricsV2FilterInput
  _or: [LfWanTxStageMetricsV2FilterInput!]
  id: StringFilterInput
  metric__bytes_metrics__input_bit_rate: LaminarFlowMetricFilterInput
  metric__bytes_metrics__output_bit_rate: LaminarFlowMetricFilterInput
  metric__bytes_metrics__total_input_bytes: LaminarFlowMetricFilterInput
  metric__bytes_metrics__total_output_bytes: LaminarFlowMetricFilterInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__received_heartbeat_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__received_lease_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__selected_lessor: StringFilterInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__transmitted_heartbeat_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__selected_lessee: StringFilterInput
  metric__control_message_manager_metrics__control_message_metrics__decryption_error_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__decryption_success_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__input_control_message_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__output_control_message_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__unsupported_input_control_message_rate: LaminarFlowMetricFilterInput
  metric__control_message_manager_metrics__control_message_metrics__unsupported_output_control_message_rate: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricFilterInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricFilterInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricFilterInput
  metric__dropped_message_count: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricFilterInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricFilterInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricFilterInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricFilterInput
}

input LfWanTxStageMetricsV2SortInput {
  id: SortOrder
  metric__bytes_metrics__input_bit_rate: LaminarFlowMetricSortInput
  metric__bytes_metrics__output_bit_rate: LaminarFlowMetricSortInput
  metric__bytes_metrics__total_input_bytes: LaminarFlowMetricSortInput
  metric__bytes_metrics__total_output_bytes: LaminarFlowMetricSortInput
  metric__common_stage_metrics__tick_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__received_heartbeat_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__received_lease_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__selected_lessor: SortOrder
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessee_metrics__transmitted_heartbeat_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_handlers_metrics__transmit_lessor_metrics__selected_lessee: SortOrder
  metric__control_message_manager_metrics__control_message_metrics__decryption_error_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__decryption_success_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__input_control_message_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__output_control_message_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__unsupported_input_control_message_rate: LaminarFlowMetricSortInput
  metric__control_message_manager_metrics__control_message_metrics__unsupported_output_control_message_rate: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricSortInput
  metric__control_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__socket_error_counts__other: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_error_count: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__recvmmsg_metrics__syscall_rate: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_datagram_count_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_max_depth_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_min_depth_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_segment_count_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__mean_width_per_syscall: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__eagain: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__econnrefused: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__ehostunreach: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__enetunreach: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__socket_error_counts__other: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_error_count: LaminarFlowMetricSortInput
  metric__data_plane_socket_metrics__sendmmsg_metrics__syscall_rate: LaminarFlowMetricSortInput
  metric__dropped_message_count: LaminarFlowMetricSortInput
  metric__input_queue_metrics__input_queue_underflow_rate: LaminarFlowMetricSortInput
  metric__input_queue_metrics__total_input_queue_underflows: LaminarFlowMetricSortInput
  metric__message_metrics__input_message_rate: LaminarFlowMetricSortInput
  metric__message_metrics__output_message_rate: LaminarFlowMetricSortInput
}

input ListDataPlaneDDXMetricInput {
  cursor: [CursorPartInput!]
  limit: Int = 10
  sort: [DataPlaneDDXMetricSortInput!]
  where: DataPlaneDDXMetricFilterInput
}

type ListDataPlaneDDXMetricOutput {
  pageInfo: PageInfo!
  results: [DataPlaneDDXMetric!]!
}

input ListDataPlaneLFMetricInput {
  cursor: [CursorPartInput!]
  limit: Int = 10
  sort: [DataPlaneLFMetricSortInput!]
  where: DataPlaneLFMetricFilterInput
}

type ListDataPlaneLFMetricOutput {
  aggregations: DataPlaneLFMetricAggregation!
  pageInfo: PageInfo!
  results: [DataPlaneLFMetric!]!
}

input ListGsocContactDetailsInput {
  cursor: [CursorPartInput!]
  limit: Int = 10
  sort: [GsocContactDetailsSortInput!]
  where: GsocContactDetailsFilterInput
}

type ListGsocContactDetailsOutput {
  aggregations: GsocContactDetailsAggregation!
  pageInfo: PageInfo!
  results: [GsocContactDetails!]!
}

input ListGsocContactsFilterInput {
  _and: [ListGsocContactsFilterInput!]
  _not: ListGsocContactsFilterInput
  _or: [ListGsocContactsFilterInput!]
  contactId: GsocStringFilterInput
  contactStatus: GsocContactStatusFilterInput
  customer: GsocCustomerFilterInput
  destinationRegions: GsocStringFilterInput
  groundStations: GsocGroundStationFilterInput
  missionProfile: GsocMissionProfileFilterInput
  satellite: GsocSatelliteFilterInput
  schedulingRegion: GsocStringFilterInput
  tags: GsocStringFilterInput
  timeRange: GsocTimeRangeFilterInput
}

input ListGsocContactsSortInput {
  contactId: SortOrder
  contactStatus: SortOrder
  customer: GsocCustomerSortInput
  missionProfile: GsocMissionProfileSortInput
  satellite: GsocSatelliteSortInput
  schedulingRegion: SortOrder
  timeRange: TimeRangeSortInput
}

scalar Long

type Measurement {
  unit: String!
  value: Float!
}

input MeasurementFilterInput {
  _and: [MeasurementFilterInput!]
  _exists: Boolean
  _not: MeasurementFilterInput
  _or: [MeasurementFilterInput!]
  unit: StringFilterInput
  value: FloatFilterInput
}

enum MeteringStatus {
  METERING_FAILED
  METERING_QUEUED
  METERING_REQUIRED_CANCELLED
  METERING_REQUIRED_COMPLETED
  METERING_REQUIRED_NEGATIVE
}

enum MeteringUsage {
  MIDBAND
  NARROWBAND
  WIDEBAND
}

type NamedImage {
  imageBase64: String!
  name: String!
}

type PageInfo {
  hasNextPage: Boolean!
  lastCursor: [CursorPart!]
  total: Total!
}

enum Polarization {
  BOTH
  LEFT_HAND
  NONE
  RIGHT_HAND
}

type PostPassImage {
  dataflowId: String
  imageBase64: String!
  jobId: String
  s3Key: String!
}

type Query {
  echo(cazJustification: String, input: EchoInput!): EchoOutput
  getContactById(cazJustification: String, input: ContactByIdInput!): Contact
  getDataPlaneDDXMetric(cazJustification: String, input: GetDataPlaneDDXMetricInput!): DataPlaneDDXMetric
  getDataPlaneLFMetric(cazJustification: String, input: GetDataPlaneLFMetricInput!): DataPlaneLFMetric
  getGsocContactDetails(cazJustification: String, input: GetGsocContactDetailsInput!): GsocContactDetails
  getUserPermissionClaims: [String!]!
  gsocGetContactDetails(cazJustification: String, input: GsocGetContactDetailsInput!): GsocContactDetails
  gsocListContactsPaginated(cazJustification: String, input: GsocListContactsPaginatedInput!): GsocListContactsPaginatedOutput
  gsocListIamRoles(cazJustification: String): GsocListIamRolesOutput
  listDataPlaneDDXMetric(cazJustification: String, input: ListDataPlaneDDXMetricInput!): ListDataPlaneDDXMetricOutput
  listDataPlaneLFMetric(cazJustification: String, input: ListDataPlaneLFMetricInput!): ListDataPlaneLFMetricOutput
  listGsocContactDetails(cazJustification: String, input: ListGsocContactDetailsInput!): ListGsocContactDetailsOutput
}

enum Relation {
  eq
  gte
}

type RequestedDagNodeInfo {
  dataflowUuid: String
  region: String
  versionedConfigId: String
  vita49StreamId: String
}

enum ReservationStatus {
  AWS_FAILED
  CANCELLED
  COMPLETED
  DELETED
  FAILED
  PASS
  POSTPASS
  PREPASS
  SCHEDULED
  SCHEDULING
}

type RfCharacteristics {
  frequencyBand: FrequencyBand
  polarization: Polarization
}

enum SortOrder {
  asc
  desc
}

input StringFilterInput {
  _eq: String
  _exists: Boolean
  _gt: String
  _gte: String
  _in: [String!]
  _lt: String
  _lte: String
  _neq: String
  _nin: [String!]
  _startsWith: String
}

type TaskReport {
  postpassReport: String
  taskId: String!
}

type TimeRange {
  endTime: AWSDateTime!
  startTime: AWSDateTime!
}

input TimeRangeFilterInput {
  _and: [TimeRangeFilterInput!]
  _exists: Boolean
  _not: TimeRangeFilterInput
  _or: [TimeRangeFilterInput!]
  endTime: TimestampFilterInput
  startTime: TimestampFilterInput
}

input TimeRangeSortInput {
  endTime: SortOrder
  startTime: SortOrder
}

input TimestampFilterInput {
  _eq: AWSDateTime
  _exists: Boolean
  _gt: AWSDateTime
  _gte: AWSDateTime
  _in: [AWSDateTime!]
  _lt: AWSDateTime
  _lte: AWSDateTime
  _neq: AWSDateTime
  _nin: [AWSDateTime!]
}

type Total {
  relation: Relation
  value: Int
}

type TranslationConfiguration {
  laminarFlowConfig: String
  type: DeliveryMechanism
  vpcAttachmentDetails: VpcAttachmentDetails
}

input TranslationConfigurationFilterInput {
  _and: [TranslationConfigurationFilterInput!]
  _exists: Boolean
  _not: TranslationConfigurationFilterInput
  _or: [TranslationConfigurationFilterInput!]
  laminarFlowConfig: StringFilterInput
  type: StringFilterInput
  vpcAttachmentDetails: VpcAttachmentDetailsFilterInput
}

type TranslationDetail {
  deliveryMechanism: DeliveryMechanism
  translationConfiguration: TranslationConfiguration
}

input TranslationDetailFilterInput {
  _and: [TranslationDetailFilterInput!]
  _exists: Boolean
  _not: TranslationDetailFilterInput
  _or: [TranslationDetailFilterInput!]
  deliveryMechanism: StringFilterInput
  translationConfiguration: TranslationConfigurationFilterInput
}

type Vita49StreamCharacteristics {
  vitaInterfaceType: Vita492SchemaType
  vitaStreamId: Int
}

enum Vita492SchemaType {
  AWS_GS_TYPE_1
  AWS_GS_TYPE_2
}

type VpcAttachmentDetails {
  customerRoleArn: String
  dataflowEndpointGroupArn: String
  securityGroupIdList: [String!]
  subnetIdList: [String!]
}

input VpcAttachmentDetailsFilterInput {
  _and: [VpcAttachmentDetailsFilterInput!]
  _exists: Boolean
  _not: VpcAttachmentDetailsFilterInput
  _or: [VpcAttachmentDetailsFilterInput!]
  customerRoleArn: StringFilterInput
  dataflowEndpointGroupArn: StringFilterInput
  securityGroupIdList: StringFilterInput
  subnetIdList: StringFilterInput
}
`;
