import React from 'react';
import { GSOCDateRangePicker } from '../../../components/inputs/GSOCDateRangePicker';
import { CommonAppLayout } from '../../../components/CommonAppLayout';
import { ContentLayout, FormField, Header, Spinner } from '@amzn/awsui-components-react';
import { RequiresPermissionClaims } from '../../../components/RequiresPermissionClaims';
import { PermissionClaim } from '../../../code-generated/PermissionClaim.generated';
import { BREADCRUMBS } from '../../pages';
import type {
  DdxMetricsResultFragment,
  LfMetricsResultFragment,
  ListDdxMetricsQuery,
} from '../../../code-generated/graphqlOperations.generated';
import {
  SortOrder,
  useListDdxMetricsQuery,
  useListLfMetricsQuery,
} from '../../../code-generated/graphqlOperations.generated';
import { uniqBy } from 'lodash';
import { format } from 'date-fns';
import { DDXGraphs } from '../../../components/atacama/Dashboards/DataPlane/DDXMetricsComponent';
import { useSearchParamDateRange } from '../../../hooks/useSearchParamDateRange';
import { LaminarFlowGraphs } from '../../../components/atacama/Dashboards/DataPlane/LaminarFlowMetricsComponent';

const DEFAULT_QUERY_LIMIT = 1000;
const FORMATTED_TIME_STR = 'yyyy-MM-dd HH:mm:ss.SSS';

export function DataPlaneDashboardPage() {
  const [dateRange, setDateRange] = useSearchParamDateRange();

  // TODO - Combine these queries and 'fetchMoreXXX' useEffect's into their own hook at some point once we have all our data present.
  const {
    data: ddxData,
    fetchMore: fetchMoreDDXMetrics,
    loading: ddxLoading,
  } = useListDdxMetricsQuery({
    variables: {
      where: {
        _and: [
          {
            endTime: { _lte: format(dateRange.endDate, FORMATTED_TIME_STR) },
          },
          {
            startTime: { _gte: format(dateRange.startDate, FORMATTED_TIME_STR) },
          },
        ],
      },
      limit: DEFAULT_QUERY_LIMIT,
      sort: [
        {
          startTime: SortOrder.Desc,
        },
      ],
    },
  });

  React.useEffect(() => {
    if (ddxData?.listDataPlaneDDXMetric?.pageInfo.hasNextPage) {
      void fetchMoreDDXMetrics({
        variables: {
          cursor: ddxData.listDataPlaneDDXMetric.pageInfo.lastCursor?.map(cursor => {
            return {
              // We do this map because the GraphQL query errors when the __typename is included.
              key: cursor.key,
              value: cursor.value,
            };
          }),
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const mergedResults = uniqBy(
            [
              ...(prev.listDataPlaneDDXMetric?.results ?? []),
              ...(fetchMoreResult.listDataPlaneDDXMetric?.results ?? []),
            ],
            metric => metric.jobId,
          );

          return {
            ...fetchMoreResult,
            listDataPlaneDDXMetric: {
              ...fetchMoreResult.listDataPlaneDDXMetric,
              results: mergedResults,
            },
          } as ListDdxMetricsQuery;
        },
      });
    }
  }, [
    ddxLoading,
    ddxData?.listDataPlaneDDXMetric?.pageInfo.hasNextPage,
    ddxData?.listDataPlaneDDXMetric?.pageInfo.lastCursor,
    ddxData?.listDataPlaneDDXMetric?.pageInfo,
    fetchMoreDDXMetrics,
  ]);

  const {
    data: lfData,
    fetchMore: fetchMoreLfMetrics,
    loading: lfLoading,
  } = useListLfMetricsQuery({
    variables: {
      where: {
        _and: [
          {
            timeStampEnd: { _lte: format(dateRange.endDate, FORMATTED_TIME_STR) },
          },
          {
            timeStampStart: { _gte: format(dateRange.startDate, FORMATTED_TIME_STR) },
          },
          {
            lfEgressStageMetricsV2: { _exists: true },
          },
        ],
      },
      limit: DEFAULT_QUERY_LIMIT,
      sort: [
        {
          timeStampStart: SortOrder.Desc,
        },
      ],
    },
  });

  React.useEffect(() => {
    if (lfData?.listDataPlaneLFMetric?.pageInfo.hasNextPage) {
      void fetchMoreLfMetrics({
        variables: {
          cursor: lfData.listDataPlaneLFMetric.pageInfo.lastCursor?.map(cursor => {
            return {
              // We do this map because the GraphQL query errors when the __typename is included.
              key: cursor.key,
              value: cursor.value,
            };
          }),
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const mergedResults = uniqBy(
            [...(prev.listDataPlaneLFMetric?.results ?? []), ...(fetchMoreResult.listDataPlaneLFMetric?.results ?? [])],
            metric => metric.id,
          );

          return {
            ...fetchMoreResult,
            listDataPlaneLFMetric: {
              ...fetchMoreResult.listDataPlaneLFMetric,
              results: mergedResults,
            },
          } as ListDdxMetricsQuery;
        },
      });
    }
  }, [
    lfLoading,
    fetchMoreLfMetrics,
    lfData?.listDataPlaneLFMetric?.pageInfo.hasNextPage,
    lfData?.listDataPlaneLFMetric?.pageInfo.lastCursor,
  ]);

  return (
    <CommonAppLayout
      content={
        <ContentLayout header={<Header variant='h1'>Data Plane Dashboard</Header>}>
          <RequiresPermissionClaims claims={[PermissionClaim.ReadMaintenance]}>
            <FormField
              label='Date Range Selection (UTC)'
              constraintText='Longer time frames may take a while to load.  Please exercise caution in queries that extend beyond a months time in this view.'
            >
              <GSOCDateRangePicker value={dateRange} onChange={setDateRange} />
            </FormField>
            <br />

            <Header variant='h2'>DDX Metrics</Header>
            <br />
            {ddxData?.listDataPlaneDDXMetric?.results !== undefined ? (
              <DDXGraphs data={ddxData.listDataPlaneDDXMetric.results as DdxMetricsResultFragment[]} />
            ) : (
              <Spinner size='large' />
            )}

            <br />

            <Header variant='h2'>Laminar Flow Metrics</Header>
            <br />
            {lfData?.listDataPlaneLFMetric?.results !== undefined ? (
              <LaminarFlowGraphs data={lfData.listDataPlaneLFMetric.results as LfMetricsResultFragment[]} />
            ) : (
              <Spinner size='large' />
            )}
          </RequiresPermissionClaims>
        </ContentLayout>
      }
      breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.DataPlaneDashboard]}
    />
  );
}
