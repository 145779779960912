import { Container, Header, LineChart, KeyValuePairs, PieChart, ColumnLayout } from '@amzn/awsui-components-react';
import type { DdxMetricsResultFragment } from '../../../../code-generated/graphqlOperations.generated';
import { useMemo } from 'react';

type DDXParameters = 'dataLatency' | 'wanLatency' | 'wanRtt';
type DDXMetric = 'wanPacketsLost' | 'wanPacketsReordered' | 'wanPacketsRepaired';

export function DDXGraphs(props: { readonly data: DdxMetricsResultFragment[] }) {
  return (
    <ColumnLayout columns={2}>
      <DDXPieGraph data={props.data} />
      <DDXStatisticGraph data={props.data} parameter='dataLatency' title='Data Latency (Milliseconds)' />
      <DDXStatisticGraph data={props.data} parameter='wanLatency' title='Wan Latency (Milliseconds)' />
      <DDXStatisticGraph data={props.data} parameter='wanRtt' title='Wan Rtt (Milliseconds)' />
      <DDXSingleMetricGraph data={props.data} parameter='wanPacketsLost' title='Wan Packets Lost (Count Per Contact)' />
      <DDXSingleMetricGraph
        data={props.data}
        parameter='wanPacketsReordered'
        title='Wan Packets Reordered (Count Per Contact)'
      />
      <DDXSingleMetricGraph
        data={props.data}
        parameter='wanPacketsRepaired'
        title='Wan Packets Repaired (Count Per Contact)'
      />
    </ColumnLayout>
  );
}

function DDXSingleMetricGraph(props: {
  readonly data: DdxMetricsResultFragment[];
  readonly title: string;
  readonly parameter: DDXMetric;
}) {
  const [data, xRangeMin, xRangeMax] = useMemo(() => {
    const data: { x: Date; y: number }[] = [];
    let xRangeMin: Date | undefined = undefined;
    let xRangeMax: Date | undefined = undefined;
    for (const metric of props.data) {
      const metricDate = new Date(metric.startTime + 'Z');
      metric.streamMetrics.forEach(streamMetric => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data.push({ x: metricDate, y: streamMetric[props.parameter]! });
      });
      if (xRangeMin == null || xRangeMin > metricDate) {
        xRangeMin = metricDate;
      }
      if (xRangeMax == null || xRangeMax < metricDate) {
        xRangeMax = metricDate;
      }
    }
    return [data, xRangeMin, xRangeMax];
  }, [props.data, props.parameter]);

  return (
    <Container header={<Header variant='h3'>{props.title}</Header>}>
      <LineChart
        xDomain={xRangeMin && xRangeMax ? [xRangeMin, xRangeMax] : undefined}
        xScaleType='time'
        xTitle='Time (UTC)'
        yTitle={props.title}
        series={[
          {
            title: props.parameter,
            type: 'line',
            data: data,
          },
        ]}
      />
    </Container>
  );
}

function DDXStatisticGraph(props: {
  readonly data: DdxMetricsResultFragment[];
  readonly title: string;
  readonly parameter: DDXParameters;
}) {
  const [avg, p99, p95, p50, xRangeMin, xRangeMax] = useMemo(() => {
    const updatedAvg: { x: Date; y: number }[] = [];
    const updatedP99: { x: Date; y: number }[] = [];
    const updatedP95: { x: Date; y: number }[] = [];
    const updatedP50: { x: Date; y: number }[] = [];
    let updatedXRangeMin: Date | undefined = undefined;
    let updatedXRangeMax: Date | undefined = undefined;
    for (const metric of props.data) {
      const metricDate = new Date(metric.startTime + 'Z');
      metric.streamMetrics.forEach(streamMetric => {
        // All these props exist at this point in time.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        updatedAvg.push({ x: metricDate, y: streamMetric[props.parameter]!.avg! });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        updatedP99.push({ x: metricDate, y: streamMetric[props.parameter]!.p99! });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        updatedP95.push({ x: metricDate, y: streamMetric[props.parameter]!.p95! });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        updatedP50.push({ x: metricDate, y: streamMetric[props.parameter]!.p50! });
      });
      if (updatedXRangeMin == null || updatedXRangeMin > metricDate) {
        updatedXRangeMin = metricDate;
      }
      if (updatedXRangeMax == null || updatedXRangeMax < metricDate) {
        updatedXRangeMax = metricDate;
      }
    }
    return [updatedAvg, updatedP99, updatedP95, updatedP50, updatedXRangeMin, updatedXRangeMax];
  }, [props.data, props.parameter]);

  return (
    <Container header={<Header variant='h3'>{props.title}</Header>}>
      <LineChart
        xDomain={xRangeMin && xRangeMax ? [xRangeMin, xRangeMax] : undefined}
        xScaleType='time'
        xTitle='Time (UTC)'
        yTitle={props.title}
        series={[
          {
            title: 'Average',
            type: 'line',
            data: avg,
          },
          {
            title: 'P99',
            type: 'line',
            data: p99,
          },
          {
            title: 'P95',
            type: 'line',
            data: p95,
          },
          {
            title: 'P50',
            type: 'line',
            data: p50,
          },
        ]}
      />
    </Container>
  );
}

function DDXHighLevelMetrics(props: { readonly data: DdxMetricsResultFragment[] }) {
  const [contacts, jobIds] = useMemo(() => {
    const updatedContacts = new Set<string>();
    const updatedJobs = new Set<string>();
    props.data.forEach(result => {
      updatedContacts.add(result.contactId);
      updatedJobs.add(result.jobId);
    });
    return [updatedContacts, updatedJobs];
  }, [props.data]);

  return (
    <KeyValuePairs
      columns={2}
      items={[
        {
          label: 'Total Contacts',
          value: contacts.size,
        },
        {
          label: 'Total Jobs',
          value: jobIds.size,
        },
      ]}
    />
  );
}

function DDXPieGraph(props: { readonly data: DdxMetricsResultFragment[] }) {
  const regions = useMemo(() => {
    const regionMap = new Map<string, { title: string; value: number }>();
    for (const metric of props.data) {
      if (regionMap.has(metric.region)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const addedValue = regionMap.get(metric.region)!.value + 1;
        regionMap.set(metric.region, {
          title: metric.region,
          value: addedValue,
        });
      } else {
        regionMap.set(metric.region, {
          title: metric.region,
          value: 1,
        });
      }
    }
    return regionMap;
  }, [props.data]);

  return (
    <Container header={<Header variant='h3'>Jobs by Region</Header>}>
      <DDXHighLevelMetrics data={props.data} />
      <br />
      <PieChart data={[...regions.values()]} />
    </Container>
  );
}
